import { Nullable } from "../../common/common"

export type PlanType = "NO_PLAN" | "CREDIT" | "MONTHLY" | "YEARLY"
export const planNameArray = ["이용 중인 플랜 없음", "웰컴 기프트 플랜", "무료 플랜", "월간 플랜", "연간 플랜", ""] as const
export type PlanNameType = typeof planNameArray[number]
export const isPlanNameValid = (planName: string | null): planName is PlanNameType => {
  return (
    planName === "이용 중인 플랜 없음" ||
    planName === "웰컴 기프트 플랜" ||
    planName === "무료 플랜" ||
    planName === "월간 플랜" ||
    planName === "연간 플랜"
  )
}
export const getPlanType = (name: string | null): PlanType => {
  if (name === "이용 중인 플랜 없음" || name === "웰컴 기프트 플랜") return "NO_PLAN"
  else if (name === "크레딧 충전") return "CREDIT"
  else if (name === "월간 플랜") return "MONTHLY"
  else if (name === "연간 플랜") return "YEARLY"
  else return "NO_PLAN"
}

export type PlanInfo = {
  id: string
  type: PlanType
  name: string
  creditAmount: number
  priceFactor: number
  description: string
  subscribePeriod: Date | ""
  price: number
}
export type PlanInfos = {
  planInfoList: PlanInfo[]
}

type PlanInfoToNullable = Omit<PlanInfo, "id" | "type">
type PlanInfoDTO = Pick<PlanInfo, "id"> & {
  [key in keyof PlanInfoToNullable]: Nullable<PlanInfoToNullable[key]>
}
export type PlanInfosDTO = Omit<PlanInfos, "planInfoList"> & {
  planInfoList: PlanInfoDTO[]
}

const planInfoFromDto = (dto: PlanInfoDTO): PlanInfo => {
  return {
    id: dto.id,
    type: getPlanType(dto.name),
    name: dto.name ?? "",
    creditAmount: dto.creditAmount ?? 0,
    priceFactor: dto.priceFactor ?? 0,
    description: dto.description ?? "",
    subscribePeriod: dto.subscribePeriod ? new Date(dto.subscribePeriod) : "",
    price: dto.price ?? 0,
  }
}
export const planInfosFromDto = (dto: PlanInfosDTO): PlanInfos => {
  const _planInfoList = dto.planInfoList.map((planInfo) => planInfoFromDto(planInfo))

  return { planInfoList: _planInfoList }
}

export const isInitialPlanInfo = (planInfo: PlanInfo) => {
  return planInfo.id === ""
}
export const initPlanInfo = (): PlanInfo => {
  return {
    id: "",
    type: "NO_PLAN",
    name: "",
    creditAmount: 0,
    priceFactor: 0,
    description: "",
    subscribePeriod: "",
    price: 0,
  }
}
export const initPlanInfos = (): PlanInfos => {
  return { planInfoList: [] }
}
