import {
  getDateLabel,
  getTimeLabel,
  inferenceStatusArray,
  initManagedInferenceHistoryInfos,
  isError,
  Loading,
  ManagedInferenceHistoryInfo,
  ManagedInferenceHistoryInfos,
  postAdminInferenceHistoriesSearchMonitor,
  QueryType,
} from "@ovision-gis-frontend/shared"
import { Chip } from "@SIAnalytics/ovision-design-system"
import { PaginationProps, Table } from "antd"
import type { ColumnsType } from "antd/es/table"
import { ColumnFilterItem } from "antd/es/table/interface"
import React, { useEffect, useState } from "react"

import TableInfo from "../TableInfo"
import styles from "./UsedStatus.module.scss"

function UsedStatus() {
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [orderColumn, setOrderColumn] = useState<"CREATED_TIME">("CREATED_TIME") // TODO: change
  const [orderDirection, setOrderDirection] = useState<"DESC" | "ASC">("DESC")
  const [managedInferenceHistoryInfos, setManagedInferenceHistoryInfos] = useState<ManagedInferenceHistoryInfos>(
    initManagedInferenceHistoryInfos,
  )
  const [dataSource, setDataSource] = useState<ManagedInferenceHistoryInfo[]>([])
  const [emailFilter, setEmailFilter] = useState<ColumnFilterItem[]>([])
  const [aiPackFilter, setAiPackFilter] = useState<ColumnFilterItem[]>([])
  const [planFilter, setPlanFilter] = useState<string[]>([])
  const [projectFilter, setProjectFilter] = useState<string[]>([])
  const [resultFilter, setResultFilter] = useState<ColumnFilterItem[]>([])
  const [searchKeyword, setSearchKeyword] = useState<string>("")

  useEffect(() => {
    const postAdminInferenceHistoriesSearchMonitorAsync = async () => {
      setIsFetching(true)
      const data: QueryType = { pageIndex, orderColumn, orderDirection }
      try {
        const _managedInferenceHistoryInfos = await postAdminInferenceHistoriesSearchMonitor(data)
        if (!isError(_managedInferenceHistoryInfos)) {
          setManagedInferenceHistoryInfos(_managedInferenceHistoryInfos)
          setDataSource(_managedInferenceHistoryInfos.managedInferenceHistoryInfoList)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setIsFetching(false)
      }
    }

    void postAdminInferenceHistoriesSearchMonitorAsync()
  }, [orderColumn, orderDirection, pageIndex])

  const onPageChange: PaginationProps["onChange"] = (page) => {
    const _page = page > 0 ? page - 1 : 0
    setPageIndex(_page)
  }

  const columns: ColumnsType<ManagedInferenceHistoryInfo> = [
    {
      title: "No.",
      dataIndex: "inferenceHistoryId",
      key: "inferenceHistoryId",
      width: "6%",
      render: (text, record, index) => <div>{index + 1 + pageIndex * 50}</div>,
    },
    {
      title: "사용자 계정",
      dataIndex: "memberEmail",
      key: "memberEmail",
      width: "14%",
    },
    {
      title: "Project 이름",
      dataIndex: "inferenceJobName",
      key: "inferenceJobName",
      width: "14%",
    },
    {
      title: "AI Pack 이름",
      dataIndex: "aiPackName",
      key: "aiPackName",
      width: "14%",
    },
    {
      title: "생성 일시",
      dataIndex: "createdTime",
      key: "createdTime",
      width: "10%",
      render: (text, record) => <div>{getDateLabel(record.createdTime, "TIL_SEC")}</div>,
    },
    {
      title: "분석 상태",
      dataIndex: "inferenceStatus",
      key: "inferenceStatus",
      width: "8%",
      render: (text, record) => {
        if (record.inferenceStatus === "COMPLETED") return <Chip size={"small"} color={"blue"} label={"Completed"} />
        else if (record.inferenceStatus === "PROCESSING") return <Chip size={"small"} color={"green"} label={"Processing"} />
        else return <Chip className={styles.error} size={"small"} label={"Error"} />
      },
    },
    {
      title: "사용 크레딧 수량",
      dataIndex: "inferencePaidCredit",
      key: "inferencePaidCredit",
      width: "8%",
      render: (text, record) => <div>{record.inferencePaidCredit.toLocaleString()}</div>,
    },
    {
      title: "분석 면적",
      dataIndex: "inferenceAreaSize",
      key: "inferenceAreaSize",
      width: "8%",
      render: (text, record) => (
        <div>{record.inferenceAreaSize.toLocaleString(navigator.language, { maximumFractionDigits: 2 }) + "km²"}</div>
      ),
    },
    {
      title: "소요 시간",
      dataIndex: "inferenceDuration",
      key: "inferenceDuration",
      width: "8%",
      render: (text, record) => <div>{getTimeLabel(record.inferenceDuration)}</div>,
    },
    {
      title: "분석 지역",
      dataIndex: "inferenceRegion",
      key: "inferenceRegion",
      width: "10%",
    },
  ]

  const projectName = [
    ...new Set(managedInferenceHistoryInfos.managedInferenceHistoryInfoList.map((history) => history.inferenceJobName)),
  ].map((project) => ({
    text: project,
    value: project,
  })) as ColumnFilterItem[]
  const userEmail = [
    ...new Set(managedInferenceHistoryInfos.managedInferenceHistoryInfoList.map((history) => history.memberEmail)),
  ].map((email) => ({
    text: email,
    value: email,
  }))

  const getCsvData = () => {
    const csvHeader = columns.map((column) => column.title)
    return [
      csvHeader,
      ...dataSource.map((data) => ({
        index: data.inferenceHistoryId,
        email: data.memberEmail,
        orderCreatedTime: data.aiPackName,
        projectName: data.inferenceJobName,
        region: data.inferenceRegion,
        areaSize: data.inferenceAreaSize,
        processingTime: data.inferenceDuration,
        usedCredit: data.inferencePaidCredit,
        processingResult: data.inferenceStatus,
      })),
    ]
  }

  return (
    <section className={styles.usedStatus}>
      <TableInfo
        className={styles.tableInfo}
        title={"사용 현황"}
        aiPackFilter={
          [
            ...new Set(managedInferenceHistoryInfos.managedInferenceHistoryInfoList.map((history) => history.aiPackName)),
          ].map((aiPackName) => ({
            text: aiPackName,
            value: aiPackName,
          })) as ColumnFilterItem[]
        }
        resultFilter={inferenceStatusArray.map((status) => ({
          text: status,
          value: status,
        }))}
        csvData={getCsvData()}
        email={userEmail}
        projectFilter={projectName}
        searchKeyword={searchKeyword}
        setAiPackFilter={setAiPackFilter}
        setEmailFilter={setEmailFilter}
        setPlanFilter={setPlanFilter}
        setProjectFilter={setProjectFilter}
        setResultFilter={setResultFilter}
        setSearchKeyword={setSearchKeyword}
        totalInfo={"사용 내역"}
        totalInfoValue={`${managedInferenceHistoryInfos.totalInferenceHistoryCount}건`}
      />
      <Table
        className={styles.table}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 50,
          showSizeChanger: false,
          total: managedInferenceHistoryInfos.totalInferenceHistoryCount,
          onChange: onPageChange,
        }}
        columns={columns}
        dataSource={dataSource}
        loading={{ indicator: <Loading className={styles.loading} size={"small"} />, spinning: isFetching }}
        rowKey={(record) => record.inferenceHistoryId}
        scroll={{ y: "calc(100% - 40px)" }} // @NOTE: head height 제외
      />
    </section>
  )
}
export default UsedStatus
