import { getMyAccount, getMyPermissions } from "../common/axiosApi"
import { initMyAccount } from "./data/myAccountDto"
import { getToken } from "./data/useToken"

export const useMember = async () => {
  let user = initMyAccount()
  let permissions: string[] = []
  if (getToken()) {
    try {
      user = await getMyAccount()
      permissions = await getMyPermissions()
    } catch (e) {
      console.error(e)
    }
  }

  return { user, permissions }
}
