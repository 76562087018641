import { Nullable } from "../../common/common"
import { getTimeLabel } from "../../common/utils/dateUtils"

export const inferenceStatusArray = ["PROCESSING", "ERROR", "COMPLETED", "SCENE_DOWNLOADING", "SCENE_DOWNLOAD_ERROR", ""]
export type ManagedInferenceStatusType = typeof inferenceStatusArray[number]
export const isInferenceStatusValid = (inferenceStatus: string | null): inferenceStatus is ManagedInferenceStatusType => {
  return (
    inferenceStatus === "PROCESSING" ||
    inferenceStatus === "ERROR" ||
    inferenceStatus === "COMPLETED" ||
    inferenceStatus === "SCENE_DOWNLOADING" ||
    inferenceStatus === "SCENE_DOWNLOAD_ERROR"
  )
}

export type ManagedInferenceHistoryInfo = {
  inferenceHistoryId: string
  inferenceAreaSize: number
  inferenceDuration: string
  inferenceJobName: string
  inferencePaidCredit: number
  inferenceRegion: string
  inferenceStatus: ManagedInferenceStatusType
  aiPackId: string
  aiPackName: string
  createdTime: Date | ""
  memberId: string
  memberEmail: string
  memberDeactivated: boolean
}
export type ManagedInferenceHistoryInfos = {
  managedInferenceHistoryInfoList: ManagedInferenceHistoryInfo[]
  totalInferenceHistoryCount: number
}

type ManagedInferenceHistoryInfoToNullable = Omit<ManagedInferenceHistoryInfo, "inferenceHistoryId">
export type ManagedInferenceHistoryInfoDTO = Pick<ManagedInferenceHistoryInfo, "inferenceHistoryId"> & {
  [key in keyof ManagedInferenceHistoryInfoToNullable]: Nullable<ManagedInferenceHistoryInfoToNullable[key]>
}
export type ManagedInferenceHistoryInfosDTO = Omit<ManagedInferenceHistoryInfos, "managedInferenceHistoryInfoList"> & {
  managedInferenceHistoryInfoList: ManagedInferenceHistoryInfoDTO[]
}

const managedInferenceHistoryInfoFromDto = (dto: ManagedInferenceHistoryInfoDTO): ManagedInferenceHistoryInfo => {
  const _inferenceStatus = isInferenceStatusValid(dto.inferenceStatus) ? dto.inferenceStatus : ""

  return {
    inferenceHistoryId: dto.inferenceHistoryId,
    inferenceAreaSize: dto.inferenceAreaSize ?? 0,
    inferenceDuration: getTimeLabel(dto.inferenceDuration ?? ""),
    inferenceJobName: dto.inferenceJobName ?? "",
    inferencePaidCredit: dto.inferencePaidCredit ?? 0,
    inferenceRegion: dto.inferenceRegion ?? "",
    inferenceStatus: _inferenceStatus,
    aiPackId: dto.aiPackId ?? "-1",
    aiPackName: dto.aiPackName ?? "",
    createdTime: dto.createdTime ? new Date(dto.createdTime) : "",
    memberId: dto.memberId ?? "-1",
    memberEmail: dto.memberEmail ?? "",
    memberDeactivated: dto.memberDeactivated ?? false,
  }
}

export const managedInferenceHistoryInfosFromDto = (dto: ManagedInferenceHistoryInfosDTO): ManagedInferenceHistoryInfos => {
  const _managedInferenceHistoryInfoList = dto.managedInferenceHistoryInfoList.map((managedInferenceHistoryInfo) =>
    managedInferenceHistoryInfoFromDto(managedInferenceHistoryInfo),
  )

  return {
    managedInferenceHistoryInfoList: _managedInferenceHistoryInfoList,
    totalInferenceHistoryCount: dto.totalInferenceHistoryCount,
  }
}

export const isInitialManagedInferenceHistoryInfo = (managedInferenceHistoryInfo: ManagedInferenceHistoryInfo) => {
  return managedInferenceHistoryInfo.inferenceHistoryId === "-1"
}
export const initManagedInferenceHistoryInfo = (): ManagedInferenceHistoryInfo => {
  return {
    inferenceHistoryId: "-1",
    inferenceAreaSize: 0,
    inferenceDuration: "",
    inferenceJobName: "",
    inferencePaidCredit: 0,
    inferenceRegion: "",
    inferenceStatus: "",
    aiPackId: "-1",
    aiPackName: "",
    createdTime: "",
    memberId: "-1",
    memberEmail: "",
    memberDeactivated: false,
  }
}
export const initManagedInferenceHistoryInfos = (): ManagedInferenceHistoryInfos => {
  return { managedInferenceHistoryInfoList: [], totalInferenceHistoryCount: 0 }
}
