import { Toast } from "@SIAnalytics/ovision-design-system"
import React from "react"
import { Trans, useTranslation } from "react-i18next"

import { postVerifyEmail } from "../../.."
import EmailOnboardingLayout, { useEmail } from "./EmailOnboardingLayout"
import styles from "./EmailOnboardingLayout.module.scss"

function SignUpOnboarding() {
  const { t } = useTranslation()
  const email = useEmail()

  const sendEmail = () => {
    const postValidateAsync = async () => {
      try {
        const _validate = await postVerifyEmail(email)
        if (_validate) {
          Toast({ message: t("toast.verifyEmail.resend.success"), type: "success" })
        } else {
          Toast({ message: t("toast.verifyEmail.resend.error"), type: "error" })
          console.error("Resend Error")
        }
      } catch (e) {
        Toast({ message: t("toast.verifyEmail.resend.error"), type: "error" })
        console.error(e)
      }
    }
    void postValidateAsync()
  }

  return (
    <EmailOnboardingLayout
      title={t("verifyEmail.title")}
      desc={
        <Trans i18nKey={"verifyEmail.desc"}>
          We have sent a verification link to <strong>email@email.com</strong> Don’t receive a verification email?
          <span className={styles.resendBtn} onClick={sendEmail}>
            Resend
          </span>
          {{ email: email }}
        </Trans>
      }
      email={email}
    />
  )
}

export default SignUpOnboarding
