import { PATH_ORDER_STATUS, PATH_USED_STATUS, PATH_USER_MANAGE, planNameArray } from "@ovision-gis-frontend/shared"
import { Button, Dropdown, FileDownloadOutlined, InputField, Toast } from "@SIAnalytics/ovision-design-system"
import { ItemOption } from "@SIAnalytics/ovision-design-system/build/src/component/data-entry/context-menu/ContextMenu"
import { ColumnFilterItem } from "antd/es/table/interface"
import cn from "classnames"
import React, { useRef } from "react"
import { useLocation } from "react-router-dom"

import styles from "./TableInfo.module.scss"

type Props = {
  className?: string
  title: string
  totalInfo: string
  totalInfoValue: string
  email?: ColumnFilterItem[]
  setEmailFilter?: React.Dispatch<React.SetStateAction<ColumnFilterItem[]>>
  setPlanFilter?: React.Dispatch<React.SetStateAction<string[]>>
  setSNSFilter?: React.Dispatch<React.SetStateAction<string[]>>
  searchKeyword?: string
  setSearchKeyword?: React.Dispatch<React.SetStateAction<string>>
  onCreditBtnClick?: React.Dispatch<React.SetStateAction<boolean>>
  csvData?: any[]
  selectedRowKeys?: React.Key[]
  projectFilter?: ColumnFilterItem[]
  aiPackFilter?: ColumnFilterItem[]
  resultFilter?: ColumnFilterItem[]
  setAiPackFilter?: React.Dispatch<React.SetStateAction<ColumnFilterItem[]>>
  setProjectFilter?: React.Dispatch<React.SetStateAction<string[]>>
  setResultFilter?: React.Dispatch<React.SetStateAction<ColumnFilterItem[]>>
}

function TableInfo(props: Props) {
  const { pathname } = useLocation()
  const linkRef = useRef<HTMLAnchorElement | null>(null)

  const onChangeEmailFilter = (value: string[] | string) => {
    if (typeof value === "string") value = [value]

    if (props.setEmailFilter) {
      props.setEmailFilter(
        value.map((v: string) => {
          return { text: v, value: v }
        }),
      )
    }
  }

  const onChangeCurrentPlan = (value: string[] | string) => {
    if (typeof value === "string") value = [value]

    if (props.setPlanFilter) props.setPlanFilter(value)
  }

  const onChangeSNS = (value: string[] | string) => {
    if (typeof value === "string") value = [value]

    if (props.setSNSFilter) props.setSNSFilter(value)
  }

  const onChangeAiPack = (value: string[] | string) => {
    if (typeof value === "string") value = [value]

    if (props.setAiPackFilter) {
      props.setAiPackFilter(
        value.map((v: string) => {
          return { text: v, value: v }
        }),
      )
    }
  }

  const onChangeProject = (value: string[] | string) => {
    if (typeof value === "string") value = [value]

    if (props.setProjectFilter) props.setProjectFilter(value)
  }

  const onChangeResult = (value: string[] | string) => {
    if (typeof value === "string") value = [value]

    if (props.setResultFilter) {
      props.setResultFilter(
        value.map((v: string) => {
          return { text: v, value: v }
        }),
      )
    }
  }

  const convertToCSV = (arr?: any[]) => {
    if (!arr) return ""
    const header = arr[0].join(",")
    const rows = arr.slice(1).map((obj: any) => Object.values(obj).join(","))
    return `${header}\n${rows.join("\n")}`
  }
  const downloadCSV = (csv: string, filename = "data.csv") => {
    if (!csv) {
      Toast({ type: "error", message: "CSV 데이터가 없습니다." })
      return
    }
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })
    const url = URL.createObjectURL(blob)
    if (linkRef.current) {
      linkRef.current.href = url
      linkRef.current.download = filename
      linkRef.current.click()
      URL.revokeObjectURL(url)
    }
  }
  const onDownloadClick: React.MouseEventHandler<HTMLElement> = (e) => {
    let fileName = pathname.slice(1)
    if (!fileName) fileName = "user-manage" // @NOTE: 사용자관리는 path가 '/'
    downloadCSV(convertToCSV(props.csvData), `${fileName}.csv`)
  }

  return (
    <div className={cn(styles.tableInfo, props.className)}>
      <p className={styles.title}>{props.title}</p>
      <div className={cn(styles.filterContainer, pathname === PATH_USED_STATUS && styles.usedStatus)}>
        <Dropdown
          selectClassName={styles.user}
          type={"multi"}
          disabled={true}
          itemList={(props.email as ItemOption[]) || []}
          placeholder={"사용자 계정"}
          search={true}
          total={true}
          onChange={onChangeEmailFilter}
        />
        {(pathname === PATH_USER_MANAGE || pathname === PATH_ORDER_STATUS) && (
          <>
            <Dropdown
              type={"multi"}
              itemList={planNameArray.map((plan) => {
                return plan !== "" ? { text: plan, value: plan } : { text: "NO VALUE", value: plan }
              })}
              disabled={true}
              placeholder={"이용중인 플랜"}
              search={true}
              total={true}
              onChange={onChangeCurrentPlan}
            />
            <Dropdown
              selectClassName={styles.plan}
              type={"multi"}
              itemList={[
                { text: "ACTIVE", value: "ACTIVE" },
                { text: "DEACTIVE", value: "DEACTIVE" },
              ]}
              disabled={true}
              placeholder={"연동 SNS"}
              search={true}
              total={true}
              onChange={onChangeSNS}
            />
          </>
        )}
        {pathname === PATH_USED_STATUS && (
          <>
            <Dropdown
              selectClassName={styles.aiPack}
              type={"multi"}
              disabled={true}
              itemList={props.aiPackFilter as ItemOption[]}
              placeholder={"AI Pack"}
              search={true}
              total={true}
              onChange={onChangeAiPack}
            />
            <Dropdown
              selectClassName={styles.project}
              type={"multi"}
              disabled={true}
              itemList={props.projectFilter as ItemOption[]}
              placeholder={"Project 이름"}
              search={true}
              total={true}
              onChange={onChangeProject}
            />
            <Dropdown
              selectClassName={styles.project}
              type={"multi"}
              disabled={true}
              itemList={props.resultFilter as ItemOption[]}
              placeholder={"처리 결과"}
              search={true}
              total={true}
              onChange={onChangeResult}
            />
          </>
        )}

        <div className={styles.searchContainer}>
          <InputField
            inputFieldClassName={styles.search}
            labelClassName={styles.label}
            disabled={true}
            placeholder={"검색어를 입력해주세요"}
            value={props.searchKeyword}
            onChange={(e) => props.setSearchKeyword?.(e.target.value)}
            onClearButtonClick={() => props.setSearchKeyword?.("")}
          />
          <Button disabled={true}>{"검색"}</Button>
        </div>
      </div>

      <div className={styles.tableDetailInfo}>
        <div className={styles.left}>
          <span>{props.totalInfo}</span>
          <span className={styles.highlightInfo}>{props.totalInfoValue}</span>
        </div>
        <div className={styles.right}>
          {props.onCreditBtnClick && (
            <Button
              size={"small"}
              disabled={true /*!props.selectedRowKeys?.length*/}
              onClick={() => props.onCreditBtnClick?.(true)}
            >
              {"크레딧 부여"}
            </Button>
          )}
          <Button size={"small"} type={"outlined"} disabled={true} icon={<FileDownloadOutlined />} onClick={onDownloadClick}>
            {"목록 다운로드"}
          </Button>
          {/* @NOTE: CSV 다운로드를 위해 필요 */}
          <a ref={linkRef} href={"#"} />
        </div>
      </div>
    </div>
  )
}
export default TableInfo
