import { Button, InputField, Toast } from "@SIAnalytics/ovision-design-system"
import { AxiosResponse } from "axios"
import cn from "classnames"
import React, { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { Link, useLocation, useNavigate } from "react-router-dom"

import {
  ACCESS_TOKEN_KEY,
  GoogleLogo,
  OE_EMAIL,
  OE_ONBOARDING,
  PATH_ANALYSIS,
  PATH_FORGOT_PASSWORD,
  PATH_HOME,
  PATH_ONBOARDING,
  PATH_ONBOARDING_EXPIRED,
  PATH_PRICING,
  PATH_SIGN_UP,
  REFRESH_TOKEN_KEY,
} from "../../.."
import { getOauthUrl, postLogin } from "../../common/axiosApi"
import { encodeToBase64 } from "../../common/common"
import styles from "./SignIn.module.scss"

type Props = {
  isAdmin: boolean
}

function SignIn(props: Props) {
  const [isSignInRequesting, setIsSignInRequesting] = useState<boolean>(false)
  const [isGoogleSignInRequesting, setIsGoogleSignInRequesting] = useState<boolean>(false)
  const [isOnboardingSuccessAlerted, setIsOnboardingSuccessAlerted] = useState<boolean>(false)
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  if (location.state) {
    const onboarding = OE_ONBOARDING in location.state ? location.state[OE_ONBOARDING] : false
    if (onboarding && !isOnboardingSuccessAlerted) {
      Toast({ message: t("toast.signIn.verification.success"), type: "success" })
      setIsOnboardingSuccessAlerted(true)
    }
  }

  useEffect(() => {
    return () => {
      setIsSignInRequesting(false)
      setIsGoogleSignInRequesting(false)
      setEmail("")
      setPassword("")
    }
  }, [])

  const isSignInDisabled = !email || !password

  const getRedirectPathname = () => {
    const searchParams = new URLSearchParams(location.search)
    const path = searchParams.get("redirect")
    if (path === PATH_PRICING) return path
    if (props.isAdmin) return PATH_HOME
    else return PATH_ANALYSIS
  }

  const onSignInBtnClick = () => {
    const emailElement = document.getElementById("email") as HTMLInputElement
    const passwordElement = document.getElementById("password") as HTMLInputElement
    if (!emailElement.checkValidity() || !passwordElement.checkValidity()) return

    setIsSignInRequesting(true)

    const postLoginAsync = async () => {
      try {
        const _login = await postLogin({ email, password })
        if (_login) {
          const _path = getRedirectPathname()
          localStorage[ACCESS_TOKEN_KEY] = _login.accessToken
          localStorage[REFRESH_TOKEN_KEY] = _login.refreshToken
          if (props.isAdmin) navigate(PATH_HOME, { replace: true })
          else navigate(_path || PATH_ANALYSIS, { replace: true })
        } else {
          Toast({ message: t("toast.signIn.error"), type: "error" })
          console.error("Sign In Error")
        }
      } catch (e) {
        const _res = e as AxiosResponse

        if (_res.status === 404) {
          Toast({ type: "error", message: t("toast.signIn.email.error") })
        } else if (_res.status === 409) {
          Toast({ type: "error", message: t("toast.signIn.googleAccount.error") })
        } else if (_res.status === 403) {
          navigate(PATH_ONBOARDING, { replace: true, state: { [OE_EMAIL]: email } })
          Toast({ message: t("toast.signIn.needVerify.error"), type: "error" })
        } else {
          Toast({ message: t("toast.signIn.error"), type: "error" })
        }
        console.error(e)
      } finally {
        setIsSignInRequesting(false)
      }
    }
    void postLoginAsync()
  }
  const onGoogleLoginBtnClick = () => {
    setIsGoogleSignInRequesting(true)

    const getLoginAsync = async () => {
      try {
        const _login = await getOauthUrl("GOOGLE")
        if (_login.data.url) {
          window.location.replace(`${_login.data.url}&state=${encodeToBase64(getRedirectPathname())}`)
        } else {
          Toast({ message: t("toast.signIn.error"), type: "error" })
          console.error("No url in response body Error")
        }
      } catch (e) {
        Toast({ message: t("toast.signIn.error"), type: "error" })
        console.error(e)
      } finally {
        setIsGoogleSignInRequesting(false)
      }
    }
    void getLoginAsync()
  }

  const onChange = (target: "EMAIL" | "PASSWORD", e: React.ChangeEvent<HTMLInputElement>) => {
    switch (target) {
      case "EMAIL":
        setEmail(e.target.value)
        return
      case "PASSWORD":
        setPassword(e.target.value)
        return
    }
  }

  const goToForgotPassword = () => {
    navigate(PATH_FORGOT_PASSWORD)
  }

  return (
    <div className={styles.signIn}>
      <div className={styles.content}>
        <p className={styles.title}>{t("login.title")}</p>
        <p className={styles.desc}>{t("login.desc")}</p>

        <form onSubmit={(e) => e.preventDefault()}>
          <InputField
            id={"email"}
            tabIndex={1}
            size={"large"}
            type={"text"}
            autoComplete={"email"}
            autoFocus={true}
            label={t("textInput.email.label") ?? ""}
            placeholder={t("textInput.placeholder", { target: t("textInput.email.label").toLowerCase() }) ?? ""}
            value={email}
            onChange={(e) => onChange("EMAIL", e)}
            onClearButtonClick={() => setEmail("")}
          />
          <InputField
            id={"password"}
            tabIndex={2}
            size={"large"}
            type={"password"}
            autoComplete={"current-password"}
            label={t("textInput.password.title") ?? ""}
            placeholder={t("textInput.placeholder", { target: t("textInput.password.title").toLowerCase() }) ?? ""}
            value={password}
            onChange={(e) => onChange("PASSWORD", e)}
            onClearButtonClick={() => setPassword("")}
          />
          <a className={cn(styles.forgotPasswordAnchor)} onClick={goToForgotPassword}>
            {t("button.forgotPassword")}
          </a>
          <Button
            className={styles.signInBtn}
            size={"xl"}
            type={"cta"}
            disabled={isSignInDisabled}
            htmlType={"submit"}
            loading={isSignInRequesting}
            onClick={onSignInBtnClick}
          >
            {t("button.signIn")}
          </Button>
          <p className={styles.navigateToSignUp}>
            <Trans i18nKey={"login.navigateToSignUp"}>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Don't have an account?
              <Link replace={true} to={PATH_SIGN_UP} onKeyDown={(e) => e.preventDefault()}>
                Sign up
              </Link>
            </Trans>
          </p>

          <div className={styles.dividerContainer}>
            <div className={styles.divider} />
            <span>{t("login.or")}</span>
            <div className={styles.divider} />
          </div>
          <Button
            className={styles.signInGoogleBtn}
            size={"xl"}
            type={"outlined"}
            htmlType={"button"}
            icon={<GoogleLogo />}
            loading={isGoogleSignInRequesting}
            onClick={onGoogleLoginBtnClick}
          >
            {t("button.signInWithGoogle")}
          </Button>
        </form>
      </div>
    </div>
  )
}
export default SignIn
