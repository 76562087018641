import { Toast } from "@SIAnalytics/ovision-design-system"
import React from "react"
import { useTranslation } from "react-i18next"
import { Navigate, useLocation, useNavigate } from "react-router-dom"

import {
  Fallback,
  OE_EMAIL,
  OE_RESET_PASSWORD_CODE,
  PATH_RESET_PASSWORD,
  PATH_RESET_PASSWORD_EXPIRED,
  PATH_SIGN_IN,
  getVerifyResetPasswordCode,
} from "../../.."

function VerifyResetPasswordCode() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const code = searchParams.get("code")
  const email = searchParams.get("email")

  const verifyCode = async (code: string) => {
    try {
      const verify = await getVerifyResetPasswordCode(code)
      if (verify.data.key === email) {
        navigate(PATH_RESET_PASSWORD, { replace: true, state: { [OE_RESET_PASSWORD_CODE]: code } })
      } else {
        Toast({ message: t("toast.verifyEmail.invalid.error"), type: "error" })
        navigate(PATH_SIGN_IN, { replace: true })
      }
    } catch (e: unknown) {
      navigate(PATH_RESET_PASSWORD_EXPIRED, { replace: true, state: { [OE_EMAIL]: email } })
    }
  }

  if (code && email) {
    verifyCode(code)
  } else {
    Toast({ message: t("toast.verifyEmail.invalid.error"), type: "error" })
    return <Navigate replace={true} to={PATH_SIGN_IN} />
  }

  return <Fallback />
}

export default VerifyResetPasswordCode
