import { Toast } from "@SIAnalytics/ovision-design-system"
import React from "react"
import { useTranslation } from "react-i18next"
import { Navigate, useLocation, useNavigate } from "react-router-dom"

import {
  Fallback,
  putVerifyEmail,
  OE_EMAIL,
  OE_ONBOARDING,
  PATH_ONBOARDING_EXPIRED,
  PATH_SIGN_IN,
  PATH_FORGOT_PASSWORD,
} from "../../.."

function VerifySignUpCode() {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const searchParams = new URLSearchParams(location.search)
  const email = searchParams.get("email")
  const code = searchParams.get("code")
  const redirectUri = searchParams.get("redirectUri")

  const isVerifiedRedirectPath = redirectUri === PATH_FORGOT_PASSWORD || redirectUri === PATH_SIGN_IN

  if (!email || !code) {
    Toast({ message: t("toast.verifyEmail.invalid.error"), type: "error" })
    return <Navigate replace={true} to={PATH_SIGN_IN} />
  } else {
    const getVerifyAsync = async () => {
      try {
        const _verify = await putVerifyEmail(code)
        if (_verify) {
          if (redirectUri && isVerifiedRedirectPath)
            navigate(redirectUri, { replace: true, state: { [OE_ONBOARDING]: true } })
          else navigate(PATH_SIGN_IN, { replace: true, state: { [OE_ONBOARDING]: true } })
        } else {
          Toast({ message: t("toast.verifyEmail.invalid.error"), type: "error" })
          console.error("Verify Error")
        }
      } catch (e) {
        navigate(PATH_ONBOARDING_EXPIRED, { replace: true, state: { [OE_EMAIL]: email } })
        console.error(e)
      }
    }
    void getVerifyAsync()
  }

  return <Fallback />
}

export default VerifySignUpCode
