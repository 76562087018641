import { Nullable } from "../../common/common"
import { getPlanType, isPlanNameValid, PlanNameType, PlanType } from "./planInfosDto"

export const orderStatusArray = ["REQUESTED", "PAID", "ERROR_OCCURRED", "DEACTIVATED", "DONE", ""]
export type OrderStatusType = typeof orderStatusArray[number]
export const isOrderStatusValid = (orderStatus: string | null): orderStatus is OrderStatusType => {
  return (
    orderStatus === "REQUESTED" ||
    orderStatus === "PAID" ||
    orderStatus === "ERROR_OCCURRED" ||
    orderStatus === "DEACTIVATED" ||
    orderStatus === "DONE"
  )
}

export type ManagedOrderInfo = {
  orderHistoryId: string
  orderRequestCredit: number
  orderStatus: OrderStatusType
  orderCreatedTime: Date | ""
  memberId: string
  memberEmail: string
  memberDeactivated: boolean
  memberRemainCredit: number
  memberNote: string
  planId: string
  planType: PlanType // @NOTE: front handling field
  planName: PlanNameType
}
export type ManagedOrderInfos = {
  managedOrderInfoList: ManagedOrderInfo[]
  totalOrderCount: number
}

type ManagedOrderInfoToNullable = Omit<ManagedOrderInfo, "orderHistoryId" | "planType">
export type ManagedOrderInfoDTO = Pick<ManagedOrderInfo, "orderHistoryId"> & {
  [key in keyof ManagedOrderInfoToNullable]: Nullable<ManagedOrderInfoToNullable[key]>
}
export type ManagedOrderInfosDTO = Omit<ManagedOrderInfos, "managedOrderInfoList"> & {
  managedOrderInfoList: ManagedOrderInfoDTO[]
}

const managedOrderInfoFromDto = (dto: ManagedOrderInfoDTO): ManagedOrderInfo => {
  const _orderStatus = isOrderStatusValid(dto.orderStatus) ? dto.orderStatus : ""
  const _planName = isPlanNameValid(dto.planName) ? dto.planName : dto.planName === "-" ? "이용 중인 플랜 없음" : ""

  return {
    orderHistoryId: dto.orderHistoryId,
    orderRequestCredit: dto.orderRequestCredit ?? 0,
    orderStatus: _orderStatus,
    orderCreatedTime: dto.orderCreatedTime ? new Date(dto.orderCreatedTime) : "",
    memberId: dto.memberId ?? "-1",
    memberEmail: dto.memberEmail ?? "",
    memberDeactivated: dto.memberDeactivated ?? false,
    memberRemainCredit: dto.memberRemainCredit ?? 0,
    memberNote: dto.memberNote ?? "",
    planId: dto.planId ?? "-1",
    planType: getPlanType(dto.planName),
    planName: _planName,
  }
}
export const managedOrderInfosFromDto = (dto: ManagedOrderInfosDTO): ManagedOrderInfos => {
  const _managedOrderInfoList = dto.managedOrderInfoList.map((managedOrderInfo) => managedOrderInfoFromDto(managedOrderInfo))

  return {
    managedOrderInfoList: _managedOrderInfoList,
    totalOrderCount: dto.totalOrderCount,
  }
}

export const isInitialManagedOrderInfo = (managedOrderInfo: ManagedOrderInfo) => {
  return managedOrderInfo.orderHistoryId === "-1"
}
export const initManagedOrderInfo = (): ManagedOrderInfo => {
  return {
    orderHistoryId: "-1",
    orderCreatedTime: "",
    orderRequestCredit: 0,
    orderStatus: "",
    memberId: "-1",
    memberEmail: "",
    memberDeactivated: false,
    memberRemainCredit: 0,
    planId: "-1",
    planType: "NO_PLAN",
    planName: "",
    memberNote: "",
  }
}
export const initManagedOrderInfos = (): ManagedOrderInfos => {
  return { managedOrderInfoList: [], totalOrderCount: 0 }
}
