import React from "react"
import { Outlet } from "react-router-dom"

import { AuthProvider } from "../../hooks/data/useAuth"
import styles from "./Layout.module.scss"

function Layout() {
  return (
    <div className={styles.layout}>
      {/* <AuthProvider> @note: For checking token is valid */}
      <Outlet />
    </div>
  )
}
export default Layout
