import { Nullable } from "../../common/common"
import { getPlanType, isPlanNameValid, PlanNameType, PlanType } from "./planInfosDto"

export type ManagedMemberInfo = {
  memberId: string
  memberEmail: string
  memberDeactivated: boolean
  memberSocialMediaType: string
  memberCreatedTime: Date | ""
  memberLastSigninTime: Date | ""
  memberRemainCredit: number
  memberTotalUsedCredit: number
  memberNote: string
  planId: string
  planType: PlanType // @NOTE: front handling field
  planName: PlanNameType
}
export type ManagedMemberInfos = {
  managedMemberInfoList: ManagedMemberInfo[]
  totalMemberCount: number
}

type ManagedMemberInfoToNullable = Omit<ManagedMemberInfo, "memberId" | "planType">
export type ManagedMemberInfoDTO = Pick<ManagedMemberInfo, "memberId"> & {
  [key in keyof ManagedMemberInfoToNullable]: Nullable<ManagedMemberInfoToNullable[key]>
}
export type ManagedMemberInfosDTO = Omit<ManagedMemberInfos, "managedMemberInfoList"> & {
  managedMemberInfoList: ManagedMemberInfoDTO[]
}

const managedMemberInfoFromDto = (dto: ManagedMemberInfoDTO): ManagedMemberInfo => {
  const _planName = isPlanNameValid(dto.planName) ? dto.planName : dto.planName === "-" ? "이용 중인 플랜 없음" : ""

  return {
    memberId: dto.memberId,
    memberEmail: dto.memberEmail ?? "",
    memberDeactivated: dto.memberDeactivated ?? false,
    memberSocialMediaType: dto.memberSocialMediaType ?? "",
    memberCreatedTime: dto.memberCreatedTime ? new Date(dto.memberCreatedTime) : "",
    memberLastSigninTime: dto.memberLastSigninTime ? new Date(dto.memberLastSigninTime) : "",
    memberRemainCredit: dto.memberRemainCredit ?? 0,
    memberTotalUsedCredit: dto.memberTotalUsedCredit ?? 0,
    memberNote: dto.memberNote ?? "",
    planId: dto.planId ?? "-1",
    planType: getPlanType(dto.planName),
    planName: _planName,
  }
}
export const managedMemberInfosFromDto = (dto: ManagedMemberInfosDTO): ManagedMemberInfos => {
  const _managedMemberInfoList = dto.managedMemberInfoList.map((managedMemberInfo) =>
    managedMemberInfoFromDto(managedMemberInfo),
  )

  return {
    managedMemberInfoList: _managedMemberInfoList,
    totalMemberCount: dto.totalMemberCount,
  }
}

export const isInitialManagedMemberInfo = (managedMemberInfo: ManagedMemberInfo) => {
  return managedMemberInfo.memberId === "-1"
}
export const initManagedMemberInfo = (): ManagedMemberInfo => {
  return {
    memberId: "-1",
    memberEmail: "",
    memberDeactivated: false,
    memberSocialMediaType: "",
    memberCreatedTime: "",
    memberLastSigninTime: "",
    memberRemainCredit: 0,
    memberTotalUsedCredit: 0,
    memberNote: "",
    planId: "-1",
    planType: "NO_PLAN",
    planName: "",
  }
}
export const initManagedMemberInfos = (): ManagedMemberInfos => {
  return { managedMemberInfoList: [], totalMemberCount: 0 }
}
