import {
  PATH_ORDER_STATUS,
  PATH_USED_STATUS,
  ProfileMenu,
  MyAccount,
  postSignOut,
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  PATH_SIGN_IN,
  PATH_USER_MANAGE,
} from "@ovision-gis-frontend/shared"
import HOneLogoImg from "@ovision-gis-frontend/shared/src/asset/logo/HOneLogo.png"
import { Toast } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React from "react"
import { NavLink, useNavigate } from "react-router-dom"

import styles from "./SideNavigationBar.module.scss"

type Props = {
  className?: string
  user: MyAccount
}

export default function SideNavigationBar(props: Props) {
  const activeClassName = styles.active
  const navigator = useNavigate()

  const onLogoutBtnClick = () => {
    const logoutAsync = async () => {
      try {
        await postSignOut()
        localStorage.removeItem(ACCESS_TOKEN_KEY)
        localStorage.removeItem(REFRESH_TOKEN_KEY)
        navigator(PATH_SIGN_IN, { replace: true })
      } catch (e) {
        Toast({ message: "로그아웃에 실패하였습니다.", type: "error" })
        console.error(e)
      }
    }
    void logoutAsync()
  }

  return (
    <nav className={cn(styles.sideNavigationBar, props.className)}>
      <div className={styles.logoWrapper}>
        <NavLink className={styles.logoLink} to={PATH_USER_MANAGE}>
          <img className={styles.logo} alt={""} src={HOneLogoImg} />
        </NavLink>
        <div className={styles.divider} />
        <span>{"Admin"}</span>
      </div>
      <ProfileMenu className={styles.profileMenu} user={props.user} onLogoutBtnClick={onLogoutBtnClick} />
      <NavLink className={({ isActive }) => (isActive ? activeClassName : undefined)} to={PATH_USER_MANAGE}>
        {"사용자 관리"}
      </NavLink>
      <NavLink className={({ isActive }) => (isActive ? activeClassName : undefined)} to={PATH_ORDER_STATUS}>
        {"주문 현황"}
      </NavLink>
      <NavLink className={({ isActive }) => (isActive ? activeClassName : undefined)} to={PATH_USED_STATUS}>
        {"사용 현황"}
      </NavLink>
    </nav>
  )
}
