import i18next from "i18next"

export const supportedLngs = ["en", "ko"] as const
export type supportedLanguage = typeof supportedLngs[number] | `en-${string}` | `ko-${string}`

export const isSupportedLanguage = (lang: string | null): lang is supportedLanguage => {
  return (lang?.includes("ko") || lang?.includes("en")) ?? false
}

export default class LanguageManager {
  // TODO: Use useLocalStorage()
  private static LANGUAGE_KEY = "Language"
  private static defaultLanguage: supportedLanguage = isSupportedLanguage(navigator.language)
    ? navigator.language.includes("-")
      ? (navigator.language.split("-")[0] as supportedLanguage)
      : navigator.language
    : "en"

  private static init = (): supportedLanguage => {
    const lang = LanguageManager.defaultLanguage
    try {
      localStorage.setItem(LanguageManager.LANGUAGE_KEY, lang)
    } catch (e) {
      console.error(e)
    }
    return lang
  }

  public static loadLanguage = (): supportedLanguage => {
    let lang = LanguageManager.defaultLanguage
    try {
      const _lang = localStorage.getItem(LanguageManager.LANGUAGE_KEY)
      if (isSupportedLanguage(_lang)) lang = _lang
    } catch (e) {
      console.error(e)
    }
    return lang
  }

  public static saveLanguage = (lang: supportedLanguage) => {
    try {
      localStorage.setItem(LanguageManager.LANGUAGE_KEY, lang)
    } catch (e) {
      console.error()
    }
    i18next.changeLanguage(lang)
  }
}
