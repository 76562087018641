import { postOrder } from "@ovision-gis-frontend/shared"
import { Button, CloseOutlined, ErrorOutlined, IconButton, InputField } from "@SIAnalytics/ovision-design-system"
import React, { useState } from "react"

import styles from "./GiveCreditModal.module.scss"

type Props = {
  onClose: () => void
  index?: number
  setIsFetching?: React.Dispatch<React.SetStateAction<boolean>>
  selectedRowKeys: React.Key[]
  getRequestCredit: () => number | undefined
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>
}

// TODO 타입 이름 고민
type Step = "single" | "multi"

export default function GiveCreditModal(props: Props) {
  const [step, setStep] = useState<Step>("single")
  const [credit, setCredit] = useState<string>("")
  const requestCredit = props.getRequestCredit()

  const onSingleStepApprove = async () => {
    if (props.selectedRowKeys.length > 1) {
      setStep("multi")
      return
    }
    try {
      await postOrder(props.selectedRowKeys.map(String), Number(credit))
    } catch (error) {
      console.log(error)
    }
    props.setRefetch((r) => !r)
    props.onClose()
  }

  const onMultiStepApprove = async () => {
    try {
      await postOrder(props.selectedRowKeys.map(String), Number(credit))
    } catch (error) {
      console.log(error)
    }
    props.setRefetch((r) => !r)
    props.onClose()
    setStep("single")
  }

  const onCreditChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filteredValue = e.target.value.replace(/[^0-9]/g, "")
    if (filteredValue) setCredit(filteredValue)
  }

  const isStringOnlyNumbers = (str: string) => {
    return /^\d+$/.test(str)
  }

  return (
    <div className={styles.wrapper}>
      {step === "single" ? (
        <div className={styles.contentWrapper}>
          <div className={styles.header}>
            <span>크레딧 부여</span>
            <IconButton
              wrapperClassName={styles.icon}
              size={"small"}
              type={"square"}
              icon={<CloseOutlined />}
              onClick={props.onClose}
            />
          </div>

          <div className={styles.content}>
            <span className={styles.title}>사용자에게 부여할 크레딧 수량을 입력하세요</span>
            <InputField className={styles.inputField} value={credit} onChange={onCreditChange} />
            {credit && requestCredit && Number(credit) !== requestCredit && isStringOnlyNumbers(credit) && (
              <span className={styles.checker}>
                입력한 크레딧이 요청 크레딧보다 {Number(credit) > requestCredit ? "많습니다." : "적습니다."}
              </span>
            )}
          </div>

          <div className={styles.footer}>
            <Button type={"outlined"} onClick={props.onClose}>
              취소
            </Button>

            <Button onClick={onSingleStepApprove}>승인</Button>
          </div>
        </div>
      ) : (
        <div className={styles.multicase}>
          <div className={styles.content}>
            <div className={styles.iconWrapper}>
              <ErrorOutlined className={styles.icon} />
            </div>

            <div className={styles.rightWrapper}>
              <div className={styles.right}>
                <div className={styles.title}>
                  {props.selectedRowKeys.length}개의 주문에서 요청한 크레딧을 부여하시겠습니까?
                </div>
                <IconButton
                  wrapperClassName={styles.icon}
                  size={"xs"}
                  type={"square"}
                  icon={<CloseOutlined />}
                  onClick={props.onClose}
                />
              </div>

              <div className={styles.footer}>
                <div className={styles.buttonGroup}>
                  <Button className={styles.cancel} type={"outlined"} onClick={props.onClose}>
                    취소
                  </Button>
                  <Button className={styles.approve} onClick={onMultiStepApprove}>
                    승인
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
