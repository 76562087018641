const leftPad = (value: number | string, max = 2) => {
  return `${value}`.padStart(max, "0")
}
export const getTimeLabel = (time?: string) => {
  if (!time) return ""
  const _times = time.split(".")
  return _times.length !== 0
    ? _times[0]
        .split(":")
        .map((_time) => leftPad(_time))
        .join(":")
    : ""
}
export const getDateLabel = (date?: string | Date, type: "TIL_DAY" | "TIL_MIN" | "TIL_SEC" = "TIL_MIN") => {
  if (!date) return ""

  const _date = typeof date === "string" ? new Date(date) : date
  const year = _date.getFullYear()
  const month = leftPad(_date.getMonth() + 1)
  const day = leftPad(_date.getDate())
  const hour = leftPad(_date.getHours())
  const min = leftPad(_date.getMinutes())
  const sec = leftPad(_date.getSeconds())

  if (type === "TIL_DAY") return [year, month, day].join("-")
  else if (type === "TIL_SEC") return `${[year, month, day].join("-")} ${[hour, min, sec].join(":")}`
  else return `${[year, month, day].join("-")} ${[hour, min].join(":")}`
}
export const isBaseDateEarlierThanTargetDate = (
  baseDate: string | Date,
  targetDate: string | Date,
  addValue: number = 0,
  type?: "YEAR" | "MONTH" | "DAY" | "HOUR" | "MIN" | "SEC",
) => {
  if (!baseDate || !targetDate) return false

  const _base = new Date(baseDate)
  const _target = new Date(targetDate)
  if (addValue) {
    if (type === "YEAR") _target.setFullYear(_target.getFullYear() + addValue)
    else if (type === "MONTH") _target.setMonth(_target.getMonth() + addValue)
    else if (type === "HOUR") _target.setHours(_target.getHours() + addValue)
    else if (type === "MIN") _target.setMinutes(_target.getMinutes() + addValue)
    else if (type === "SEC") _target.setSeconds(_target.getSeconds() + addValue)
    else _target.setDate(_target.getDate() + addValue)
  }
  return _base.getTime() < _target.getTime()
}
