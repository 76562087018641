import {
  getDateLabel,
  initManagedMemberInfos,
  isError,
  Loading,
  ManagedMemberInfo,
  ManagedMemberInfos,
  PATH_USED_STATUS,
  postAdminMembersSearchMonitor,
  QueryType,
} from "@ovision-gis-frontend/shared"
import { ArrowRightFilled } from "@SIAnalytics/ovision-design-system"
import { PaginationProps, Table } from "antd"
import type { ColumnsType } from "antd/es/table"
import { ColumnFilterItem } from "antd/es/table/interface"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import TableInfo from "../TableInfo"
import MemoEditor from "./MemoEditor"
import styles from "./UserManage.module.scss"

function UserManage() {
  const [refetch, setRefetch] = useState<boolean>(false)
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [orderColumn, setOrderColumn] = useState<"CREATED_TIME">("CREATED_TIME") // TODO: change
  const [orderDirection, setOrderDirection] = useState<"DESC" | "ASC">("DESC")
  const [managedMemberInfoList, setManagedMemberInfoList] = useState<ManagedMemberInfos>(initManagedMemberInfos)
  const [dataSource, setDataSource] = useState<ManagedMemberInfo[]>([])
  const [emailFilter, setEmailFilter] = useState<ColumnFilterItem[]>([])
  const [planFilter, setPlanFilter] = useState<string[]>([])
  const [snsFilter, setSNSFilter] = useState<string[]>([])
  const [currentSelectedUser, setCurrentSelectedUser] = useState<string>("-1")
  const [isMemoEditorVisible, setIsMemoEditorVisible] = useState<boolean>(false)
  const [searchKeyword, setSearchKeyword] = useState<string>("")

  useEffect(() => {
    const setAdminMembersSearchMonitorAsync = async () => {
      setIsFetching(true)
      const data: QueryType = { pageIndex, orderColumn, orderDirection }
      try {
        const _managedMemberInfos = await postAdminMembersSearchMonitor(data)
        if (!isError(_managedMemberInfos)) {
          setManagedMemberInfoList(_managedMemberInfos)
          setDataSource(_managedMemberInfos.managedMemberInfoList)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setIsFetching(false)
      }
    }

    void setAdminMembersSearchMonitorAsync()
  }, [orderColumn, orderDirection, pageIndex, refetch])

  const onMemoClick = (id: string) => {
    setCurrentSelectedUser(id)
    setIsMemoEditorVisible(true)
  }
  const onMemoClose = () => {
    setIsMemoEditorVisible(false)
  }

  const columns: ColumnsType<ManagedMemberInfo> = [
    {
      title: "No.",
      dataIndex: "memberId",
      key: "memberId",
      width: "6%",
      render: (text, record, index) => <div>{index + 1 + pageIndex * 50}</div>,
    },
    {
      title: "연동 SNS",
      dataIndex: "memberDeactivated",
      key: "memberDeactivated",
      width: "6%",
      render: (text, record) => {
        return record.memberDeactivated ? (
          <img alt={""} src={"/google-disabled.svg"} />
        ) : (
          <img alt={""} src={"/google.svg"} />
        )
      },
    },
    {
      title: "사용자 계정",
      dataIndex: "memberEmail",
      key: "memberEmail",
      width: "20%",
      render: (text, record) => (
        <Link
          style={{ display: "flex", alignItems: "center", cursor: "pointer", color: "var(--text-main)" }}
          to={PATH_USED_STATUS}
          onClick={(e) => e.stopPropagation()}
        >
          <span style={{ textDecoration: "underline" }}>{record.memberEmail}</span>
          <ArrowRightFilled />
        </Link>
      ),
    },
    {
      title: "가입 일시",
      dataIndex: "memberCreatedTime",
      key: "memberCreatedTime",
      width: "10%",
      render: (text, record) => <div>{getDateLabel(record.memberCreatedTime, "TIL_SEC")}</div>,
    },
    {
      title: "이용중인 플랜",
      dataIndex: "planName",
      key: "planName",
      width: "10%",
    },
    {
      title: "누적 사용 크레딧",
      dataIndex: "memberTotalUsedCredit",
      key: "memberTotalUsedCredit",
      width: "10%",
      render: (text, record) => <div>{record.memberTotalUsedCredit.toLocaleString()}</div>,
    },
    {
      title: "보유 크레딧 수량",
      dataIndex: "memberRemainCredit",
      key: "memberRemainCredit",
      width: "10%",
      render: (text, record) => <div>{record.memberRemainCredit.toLocaleString()}</div>,
    },
    {
      title: "최근 접속일시",
      dataIndex: "memberLastSigninTime",
      key: "memberLastSigninTime",
      width: "10%",
      render: (text, record) => <div>{getDateLabel(record.memberLastSigninTime, "TIL_SEC")}</div>,
    },
    {
      title: "기타 정보",
      dataIndex: "memberNote",
      key: "memberNote",
      width: "18%",
      render: (text, record) => (
        <div className={styles.memo}>
          <span className={styles.text}>{text}</span>
          <span onClick={() => onMemoClick(record.memberId)}>
            <img className={styles.icon} alt={"google"} src={"/memo.svg"} />
          </span>

          {isMemoEditorVisible && record.memberId === currentSelectedUser && (
            <MemoEditor
              memberId={record.memberId}
              memberNote={record.memberNote}
              setRefetch={setRefetch}
              onClose={onMemoClose}
              onSubmit={() => {}}
            />
          )}
        </div>
      ),
    },
  ]

  const onPageChange: PaginationProps["onChange"] = (page) => {
    const _page = page > 0 ? page - 1 : 0
    setPageIndex(_page)
  }

  const getCsvData = () => {
    const csvHeader = columns.map((column) => column.title).slice(0, -1)
    return [
      csvHeader,
      ...dataSource.map((data) => {
        const _memberNote = data.memberNote.replaceAll("\n", " ")
        return {
          index: data.memberId,
          deactivated: data.memberDeactivated,
          email: data.memberEmail,
          createdTime: data.memberCreatedTime,
          planName: data.planName,
          totalUsedCredit: data.memberTotalUsedCredit,
          remainCredit: data.memberRemainCredit,
          lastSigninTime: data.memberLastSigninTime,
          memo: _memberNote,
        }
      }),
    ]
  }

  return (
    <section className={styles.userManage}>
      <TableInfo
        className={styles.tableInfo}
        title={"사용자 관리"}
        email={
          managedMemberInfoList.managedMemberInfoList.map((member) => {
            return { text: member.memberEmail, value: member.memberEmail }
          }) as ColumnFilterItem[]
        }
        csvData={getCsvData()}
        searchKeyword={searchKeyword}
        setEmailFilter={setEmailFilter}
        setPlanFilter={setPlanFilter}
        setSNSFilter={setSNSFilter}
        setSearchKeyword={setSearchKeyword}
        totalInfo={"전체 사용자"}
        totalInfoValue={`${managedMemberInfoList.totalMemberCount}명`}
      />
      <Table
        className={styles.table}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 50,
          showSizeChanger: false,
          total: managedMemberInfoList.totalMemberCount,
          onChange: onPageChange,
        }}
        columns={columns}
        dataSource={dataSource}
        loading={{ indicator: <Loading className={styles.loading} size={"small"} />, spinning: isFetching }}
        rowKey={(record) => record.memberId}
        scroll={{ y: "calc(100% - 40px)" }} // @NOTE: head height 제외
      />
    </section>
  )
}
export default UserManage
