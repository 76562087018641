import process from "process"

import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios"

import { PATH_OAUTH_GOOGLE, PATH_SIGN_IN } from "../.."
import { AiPackInfosDTO, aiPackInfosFromDto, AiPackInquiryRequestType } from "../hooks/data/aiPackInfosDto"
import { OauthType, TokenDto } from "../hooks/data/authDto"
import { CreditAllResultsDto, creditAllResultsFromDto } from "../hooks/data/creditAllResultsDto"
import { CreditRechargeResultsDto, creditRechargeResultsFromDto } from "../hooks/data/creditRechargeResultsDto"
import { CreditUsageResultsDto, creditUsageResultsFromDto } from "../hooks/data/creditUsageResultsDto"
import { AnalysisStatusType, InferenceJobInfosDTO, inferenceJobInfosFromDto } from "../hooks/data/inferenceJobInfosDto"
import { InferenceResultDto, inferenceResultFromDto } from "../hooks/data/inferenceResultDto"
import { InferenceRequestType, InferencesDto, inferencesFromDto } from "../hooks/data/inferencesDto"
import {
  ManagedInferenceHistoryInfosDTO,
  managedInferenceHistoryInfosFromDto,
} from "../hooks/data/managedInferenceHistoryInfosDto"
import { ManagedMemberInfosDTO, managedMemberInfosFromDto } from "../hooks/data/managedMemberInfosDto"
import { ManagedOrderInfosDTO, managedOrderInfosFromDto } from "../hooks/data/managedOrderInfosDto"
import { MapBoxGeocodingDto, predictionsFromDto } from "../hooks/data/mapboxDto"
import { MembershipRequestType } from "../hooks/data/membershipDto"
import { MembershipHistoryInfosDTO, membershipHistoryInfosFromDto } from "../hooks/data/membershipHistoryDto"
import { MemberRequestType, MyAccount, myAccountFromDto } from "../hooks/data/myAccountDto"
import { OrderDto, orderFromDto } from "../hooks/data/orderDto"
import { PlanInfosDTO, planInfosFromDto } from "../hooks/data/planInfosDto"
import {
  ProjectRequestType,
  ProjectsDTO,
  projectsFromDto,
  ProjectType,
  PutProjectRequestType,
} from "../hooks/data/projectDto"
import { ProviderDTO, providerFromDto } from "../hooks/data/providerDto"
import { RefundDto, refundFromDto } from "../hooks/data/refundDto"
import { GsdRange, satellitesFromDTO, ScenesDTO, scenesFromDto } from "../hooks/data/scenesDto"
import {
  SyncStorageInquiryRequestType,
  SyncStorageRequestType,
  SyncStoragesDto,
  syncStoragesFromDto,
} from "../hooks/data/syncStorageDto"
import { ACCESS_TOKEN_KEY, getToken, REFRESH_TOKEN_KEY } from "../hooks/data/useToken"
import { changeToSnake, keysToCamel, keysToSnake } from "./common"

const SERVER_URI_OLD = "https://ovision-api-gateway-22jj21e1.an.gateway.dev"
const SERVER_PROD_URI = "https://api.ovision-gis.com"
const SERVER_BETA_URI = "https://api.beta-ovision-gis.com"
const SERVER_DEV_API = "https://api.dev-ovision-gis.com"

export const DOMAIN_NAME = process.env.REACT_APP_DOMAIN ?? "dev-ovision-gis.com"
export const SERVER_URI = `https://api.${DOMAIN_NAME}`

export const axiosApi = (url: string) => axios.get(url).then((res) => res.data)

const responseData = (response: AxiosResponse) => response.data
const responseStatus = (response: AxiosResponse) => response.status

const error = (error: AxiosError) => {
  const { response } = error
  if (response) throw { data: response.data, status: response.status, url: error.config?.url }
  else throw error
}
export const isError = (error: any): error is Error => {
  return error instanceof Error
}

type RequestConfig = AxiosRequestConfig & {
  sent: number
}
const instance = axios.create({
  baseURL: SERVER_URI,
  withCredentials: true,
  headers: { "Accept-Language": localStorage.i18nextLng },
})

let isRefreshingToken = false

instance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError): Promise<AxiosError> => {
    const prevRequestConfig = error.config as RequestConfig
    if (!prevRequestConfig.sent) prevRequestConfig.sent = 0
    if (error.code === "ERR_NETWORK" && error.config?.method === "get" && prevRequestConfig.sent < 3) {
      prevRequestConfig.sent++
      prevRequestConfig.headers = { Authorization: `Bearer ${getToken()}`, "Content-Type": "text/plain" }
      return instance(prevRequestConfig)
    } else if (error.response?.status === 401 && !isRefreshingToken) {
      return tokenExpiredHandling(error)
    }
    return Promise.reject(error)
  },
)

const tokenExpiredHandling = async (e: AxiosError): Promise<AxiosError<unknown, any>> => {
  try {
    const refreshToken = localStorage[REFRESH_TOKEN_KEY]
    isRefreshingToken = true
    const res = await postRefreshToken(refreshToken)
    localStorage[ACCESS_TOKEN_KEY] = res.accessToken
    localStorage[REFRESH_TOKEN_KEY] = res.refreshToken
    const config = e.config as AxiosRequestConfig
    config.headers = { Authorization: `Bearer ${getToken()}`, "Content-Type": "text/plain" }
    return axios.request(config)
  } catch {
    localStorage.removeItem(ACCESS_TOKEN_KEY)
    localStorage.removeItem(REFRESH_TOKEN_KEY)
    console.error("Failed to certification.")
    window.location.replace(`${window.location.origin}${PATH_SIGN_IN}`)
    return Promise.reject(e)
  } finally {
    isRefreshingToken = false
  }
}

const requests = {
  get: (url: string, token?: string, resStatus = false) =>
    token
      ? instance
          .get(url, {
            headers: { Authorization: `Bearer ${token}`, "Content-Type": "text/plain" },
          })
          .then(resStatus ? responseStatus : responseData)
          .catch(error)
      : instance.get(url).catch(error),
  // @NOTE: changeKeysToSnake는 점진적으로 camelCase로 바꾼 후 제거
  post: (url: string, data: any = {}, token?: string, resStatus = false, changeKeysToSnake = true) =>
    token
      ? data instanceof FormData
        ? instance
            .post(url, data, {
              headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" },
            })
            .then(resStatus ? responseStatus : responseData)
            .catch(error)
        : instance
            .post(url, changeKeysToSnake ? keysToSnake(data) : data, {
              headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
            })
            .then(resStatus ? responseStatus : responseData)
            .catch(error)
      : instance.post(url, changeKeysToSnake ? keysToSnake(data) : data).catch(error),
  delete: (url: string, token?: string) =>
    token
      ? instance
          .delete(url, {
            headers: { Authorization: `Bearer ${token}`, "Content-Type": "text/plain" },
          })
          .then(responseStatus)
          .catch(error)
      : instance.delete(url).then(responseStatus).catch(error),
  put: (url: string, data: any = {}, token?: string) =>
    token
      ? instance
          .put(url, data, {
            headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
          })
          .then(responseStatus)
          .catch(error)
      : instance.put(url, data).then(responseStatus).catch(error),
  patch: (url: string, data: any = {}, token?: string) =>
    token
      ? instance
          .patch(url, data, {
            headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
          })
          .then(responseStatus)
          .catch(error)
      : instance.patch(url, data).then(responseStatus).catch(error),
}

export type QueryType = {
  pageIndex?: number
  pageSize?: number
  orderColumn?: string
  orderDirection?: string
  startTime?: string
  endTime?: string
}
export type PageQueryType = {
  startTime?: string
  endTime?: string
  page?: string
  pageSize?: string
  nextPageCount?: string
}

const generateQueryString = (option?: any, stringCase?: "CAMEL" | "SNAKE"): string => {
  if (!option) return ""

  const queryStrings: string[] = []
  for (const key in option) {
    const value = option[key as keyof any]
    if (value || value === 0) {
      const _key = stringCase === "CAMEL" ? key : changeToSnake(key) // @NOTE: 초기 snake로 보내는 스펙이었음
      if (value instanceof Array) {
        if (value.length === 0) continue
        queryStrings.push(listToQueryString(_key, value))
      } else {
        queryStrings.push(`${_key}=${value}`)
      }
    }
  }
  return "?" + queryStrings.join("&")
}

const listToQueryString = (key: string, list: any[]): string => {
  return list.map((value) => `${key}=${value}`).join("&")
}

export const postLogin = (data: { email: string; password: string }) => {
  return requests.post("/v2/accounts/auth/basic/sign-in", data).then((data) => data.data as TokenDto)
}

export const getOauthUrl = (type: OauthType) => {
  return requests.get(`/v2/accounts/auth/oauth/url?type=${type}&redirectUri=${window.location.origin + PATH_OAUTH_GOOGLE}`)
}

export const postOauthSignIn = (type: OauthType, code: string) => {
  return requests
    .post(`/v2/accounts/auth/oauth/sign-in?type=${type}&redirectUri=${window.location.origin + PATH_OAUTH_GOOGLE}`, { code })
    .then((data) => data.data as TokenDto)
}

export const postRefreshToken = (refreshToken: string) => {
  const token = getToken()
  if (!token) throw new Error("Token error")
  return requests.post("/v2/accounts/auth/refresh", { refreshToken }, token, false, false).then((data) => data as TokenDto)
}

export const postSignUp = (data: MemberRequestType) => {
  return requests.post("/v2/accounts/auth/basic/sign-up", data, undefined, false, false)
}

export const putVerifyEmail = (code: string) => {
  return requests.put(`/v2/accounts/support/verify-email?code=${code}`)
}

export const postVerifyEmail = (email: string) => {
  return requests.post("/v2/accounts/support/verify-email", { key: email })
}

export const postSignOut = () => {
  const token = getToken()
  if (!token) throw new Error("Token error")
  return requests.post("/v2/accounts/auth/sign-out", {}, token)
}

export const postSignOutAll = () => {
  const token = getToken()
  if (!token) throw new Error("Token error")
  return requests.delete("/v2/my-account/sessions", token)
}

export const getMyAccount = () => {
  const token = getToken()
  if (!token) throw new Error("Token error")
  return requests.get("/v2/my-account", token).then((memberInfo) => myAccountFromDto(memberInfo))
}

export const getMyPermissions = () => {
  const token = getToken()
  if (!token) throw new Error("Token error")
  return requests.get("/v2/my-account/permissions", token).then((data) => (data.permissions as string[]) ?? [])
}

export const deleteAccount = () => {
  const token = getToken()
  if (!token) throw new Error("Token error")
  return requests.delete("/v2/my-account", token)
}

export const patchProfile = (data: { jobTitle: string; organizationName: string; countryCode: string }) => {
  const token = getToken()
  if (!token) throw new Error("Token error")
  return requests.patch("/v2/my-account/profile", data, token)
}

export const postPromotions = (promotionCode: string | null) => {
  const token = getToken()
  if (!token) throw new Error("Token error")
  return requests.post("/v2/my-account/promotions", { promotionCode }, token, false, false)
}

export const getInferenceJobInfos = (query?: QueryType) => {
  const token = getToken()
  if (!token) return new Error("Token error")
  const _query = generateQueryString(query)
  return requests
    .get(`/inferences${_query}`, token)
    .then((data) => keysToCamel(data) as InferenceJobInfosDTO)
    .then((jobInfos) => inferenceJobInfosFromDto(jobInfos))
}

export const postInferences = (data: InferenceRequestType) => {
  const token = getToken()
  if (!token) return new Error("Token error")
  return requests
    .post("/inferences", data, token)
    .then((data) => keysToCamel(data) as InferencesDto)
    .then((inference) => inferencesFromDto(inference))
}

export const deleteInferences = (id: string) => {
  const token = getToken()
  if (!token) return new Error("Token error")
  return requests.delete(`/inferences/${id}`, token)
}

export const getInferenceResult = (jobId: string, download: boolean = true) => {
  const token = getToken()
  if (!token) return new Error("Token error")
  return requests
    .get(`/inferences/${jobId}/results${download ? `?download=${download}` : ""}`, token)
    .then((data) => keysToCamel(data) as InferenceResultDto)
    .then((results) => inferenceResultFromDto(results))
}

export const getMembershipHistoryInfos = (query?: QueryType) => {
  const token = getToken()
  if (!token) return new Error("Token error")
  const _query = generateQueryString(query)
  return requests
    .get(`/membership/histories${_query}`, token)
    .then((data) => keysToCamel(data) as MembershipHistoryInfosDTO)
    .then((historyInfos) => membershipHistoryInfosFromDto(historyInfos))
}

export type ScenesQueryType = QueryType & {
  keyword?: string
  imagingStartTime?: string
  imagingEndTime?: string
  createdStartTime?: string
  createdEndTime?: string
  satelliteList?: (string | null)[]
  minGsd?: number
  maxGsd?: number
}
export const getScenes = (query?: QueryType) => {
  const token = getToken()
  if (!token) return new Error("Token error")
  const _query = generateQueryString(query)
  return requests
    .get(`/scenes${_query}`, token)
    .then((data) => keysToCamel(data) as ScenesDTO)
    .then((scenes) => scenesFromDto(scenes))
}
export const deleteScenes = (id: string) => {
  const token = getToken()
  if (!token) return new Error("Token error")
  return requests.delete(`/scenes/${id}`, token)
}

export const postScene = (data: FormData) => {
  const token = getToken()
  if (!token) return new Error("Token error")
  return requests.post(`scene/upload?overwrite=true`, data, token)
}

export const getScene = () => {
  const token = getToken()
  if (!token) return new Error("Token error")
  return requests
    .get("/scene", token)
    .then((data) => data as ProviderDTO)
    .then((scene) => providerFromDto(scene))
}

export const getAiPacks = () => {
  const token = getToken()
  if (!token) return new Error("Token error")
  return requests
    .get("/ai-packs", token)
    .then((data) => keysToCamel(data) as AiPackInfosDTO)
    .then((aiPacks) => aiPackInfosFromDto(aiPacks))
}

export const postAiPacksInquiry = (aiPackId: string, data: AiPackInquiryRequestType) => {
  const token = getToken()
  if (!token) return new Error("Token error")
  return requests.post(`/ai-packs/${aiPackId}/inquiry`, data, token, true)
}

export const postSceneUpload = (fileName: string, fileSize: string) => {
  const token = getToken()
  if (!token) throw new Error("Token error")
  return requests
    .post(`/v2/scenes/upload`, { fileName, fileSize }, token, false, false)
    .then((value) => keysToCamel(value) as { hashName: string; signedUrl: string })
}

export const patchSceneStatus = (hashName: string, status: "COMPLETED" | "FAILED") => {
  const token = getToken()
  if (!token) throw new Error("Token error")
  return requests.patch(`/v2/scenes/${hashName}`, { uploadStatus: status }, token)
}

/* Admin Web */
export const postAdminMembersSearchMonitor = (data: QueryType) => {
  const token = getToken()
  if (!token) return new Error("Token error")
  return requests
    .post("/admin/members/search-monitor", data, token)
    .then((data) => keysToCamel(data) as ManagedMemberInfosDTO)
    .then((managedMemberInfos) => managedMemberInfosFromDto(managedMemberInfos))
}

export const postAdminOrdersSearchMonitor = (data: QueryType) => {
  const token = getToken()
  if (!token) return new Error("Token error")
  return requests
    .post("/admin/orders/search-monitor", data, token)
    .then((data) => keysToCamel(data) as ManagedOrderInfosDTO)
    .then((managedOrderInfos) => managedOrderInfosFromDto(managedOrderInfos))
}

export const postAdminInferenceHistoriesSearchMonitor = (data: QueryType) => {
  const token = getToken()
  if (!token) return new Error("Token error")
  return requests
    .post("/admin/inference-histories/search-monitor", data, token)
    .then((data) => keysToCamel(data) as ManagedInferenceHistoryInfosDTO)
    .then((managedInferenceHistoryInfo) => managedInferenceHistoryInfosFromDto(managedInferenceHistoryInfo))
}

export const postMembership = (data: MembershipRequestType) => {
  const token = getToken()
  if (!token) return new Error("Token error")
  return requests.post("/membership", data, token, true)
}

export const putMemberNote = (memberId: string, note: string) => {
  const token = getToken()
  if (!token) return new Error("Token error")

  return requests.put(`/admin/members/${memberId}`, { note }, token)
}

// TODO: object 하나로 parameter 변경
export const postOrder = (orderId: string[], creditAmount: number) => {
  const token = getToken()
  if (!token) return new Error("Token error")
  return requests.post("/admin/orders", { orderIdList: orderId, creditAmount: creditAmount }, token)
}

export const getPredictions = (searchKeyword: string) => {
  const MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_API_KEY
  if (!MAPBOX_API_KEY) throw new Error("MAPBOX_API_KEY does not exist.")

  return axiosApi(`https://api.mapbox.com/geocoding/v5/mapbox.places/${searchKeyword}.json?access_token=${MAPBOX_API_KEY}`)
    .then((data) => keysToCamel(data) as MapBoxGeocodingDto)
    .then((predictions) => predictionsFromDto(predictions))
}

export const getSyncStorages = () => {
  const token = getToken()
  if (!token) return new Error("Token error")
  return requests
    .get("/v1/sync-storages", token)
    .then((data) => keysToCamel(data) as SyncStoragesDto)
    .then((syncStorages) => syncStoragesFromDto(syncStorages))
}

export const postSyncStorages = (data: SyncStorageRequestType) => {
  const token = getToken()
  if (!token) return new Error("Token error")
  return requests.post("/v1/sync-storages", data, token, true)
}

export const postSyncStoragesInquiry = (data: SyncStorageInquiryRequestType) => {
  const token = getToken()
  if (!token) return new Error("Token error")
  return requests.post("/v1/sync-storages/inquiry", data, token, true)
}

export const getSyncStoragesProject = (id: string) => {
  const token = getToken()
  if (!token) return new Error("Token error")
  return requests
    .get(`/v1/sync-storages/${id}`, token)
    .then((data) => keysToCamel(data) as ProjectsDTO)
    .then((projects) => projectsFromDto(projects))
}

export const deleteSyncStorages = (id: string, force: boolean = false) => {
  const token = getToken()
  if (!token) return new Error("Token error")
  return requests.delete(`/v1/sync-storages/${id}?force=${force}`, token)
}

export type ProjectsQueryType = QueryType & {
  services?: string
  types?: ProjectType
  sceneId?: string
  keyword?: string
  aiPackIdList?: string[]
}
export const getProjects = (query?: ProjectsQueryType) => {
  const token = getToken()
  if (!token) return new Error("Token error")
  const _query = generateQueryString(query)
  return requests
    .get(`/v1/projects${_query}`, token)
    .then((data) => keysToCamel(data) as ProjectsDTO)
    .then((projects) => projectsFromDto(projects))
}

export const postProjects = (data: ProjectRequestType) => {
  const token = getToken()
  if (!token) return new Error("Token error")
  return requests.post("/v1/projects", data, token)
}

export const putProjects = (data: PutProjectRequestType) => {
  const token = getToken()
  if (!token) return new Error("Token error")
  return requests.put(`/v1/projects/${data.id}`, data, token)
}

export const deleteProject = (id: string) => {
  const token = getToken()
  if (!token) return new Error("Token error")
  return requests.delete(`/v1/projects/${id}`, token)
}

export type JobsQueryType = QueryType & {
  keyword?: string
  imagingStartTime?: string
  imagingEndTime?: string
  createdStartTime?: string
  createdEndTime?: string
  statuses?: AnalysisStatusType[]
  satellites?: string[]
  resolutionMin?: number
  resolutionMax?: number
}

export const getProjectsInferenceJobs = (id: string, query?: JobsQueryType) => {
  const token = getToken()
  if (!token) return new Error("Token error")
  const _query = generateQueryString(query)
  return requests
    .get(`/v1/projects/${id}/jobs${_query}`, token)
    .then((data) => keysToCamel(data) as InferenceJobInfosDTO)
    .then((jobInfos) => inferenceJobInfosFromDto(jobInfos))
}

export const getPlans = () => {
  const token = getToken()
  if (!token) return new Error("Token error")
  return requests
    .get("/plans", token)
    .then((data) => keysToCamel(data) as PlanInfosDTO)
    .then((planInfos) => planInfosFromDto(planInfos))
}

export const getSatellites = () => {
  const token = getToken()
  if (!token) throw new Error("Token error")
  return requests.get("/v1/scenes/satellites", token).then((data) => satellitesFromDTO(data))
}

export const getGsdRange = () => {
  const token = getToken()
  if (!token) throw new Error("Token error")
  return requests.get("/v1/scenes/gsd-range", token).then((data) => {
    const _data = keysToCamel(data)
    return [_data.minGsd, _data.maxGsd] as GsdRange
  })
}

export const getJobSatellites = (id: string) => {
  const token = getToken()
  if (!token) throw new Error("Token error")
  return requests.get(`/v1/projects/${id}/satellites`, token).then((data) => satellitesFromDTO(data))
}

export const getJobGsdRange = (id: string) => {
  const token = getToken()
  if (!token) throw new Error("Token error")
  return requests.get(`/v1/projects/${id}/gsd-range`, token).then((data) => {
    const _data = keysToCamel(data)
    return [_data.minGsd, _data.maxGsd] as GsdRange
  })
}

export const postPreviewCredit = (data: InferenceRequestType) => {
  const token = getToken()
  if (!token) throw new Error("Token error")
  return requests
    .post("/inferences/preview", data, token)
    .then((data) => keysToCamel(data) as { paidCredit: number; areaSize: number })
}

// Payments
export const postOrders = (data: { planId: string }) => {
  const token = getToken()
  if (!token) throw new Error("Token error")
  return requests
    .post("/v2/orders", data, token)
    .then((data) => data as OrderDto)
    .then((order) => orderFromDto(order))
}
export const postOrdersCancel = (orderKey: string, data: { data: { code: string; message: string } }) => {
  const token = getToken()
  if (!token) throw new Error("Token error")
  return requests
    .post(`/v2/orders/key/${orderKey}/cancel`, data, token)
    .then((data) => data as OrderDto)
    .then((order) => orderFromDto(order))
}
export const postOrdersConfirm = (orderKey: string, data: { paymentKey: string; amount: number; currency: string }) => {
  const token = getToken()
  if (!token) throw new Error("Token error")
  return requests
    .post(`/v2/orders/key/${orderKey}/confirm`, data, token)
    .then((data) => data as OrderDto)
    .then((order) => orderFromDto(order))
}
export const getOrdersRefund = (orderKey: string) => {
  const token = getToken()
  if (!token) throw new Error("Token error")
  return requests
    .get(`/v2/orders/key/${orderKey}/refund`, token)
    .then((data) => data as RefundDto)
    .then((refund) => refundFromDto(refund))
}
export const postOrdersRefund = (orderKey: string, data: { reason: string }) => {
  const token = getToken()
  if (!token) throw new Error("Token error")
  return requests.post(`/v2/orders/key/${orderKey}/refund`, data, token)
}

// My-Credit
export const getMyCreditAll = (query?: PageQueryType) => {
  const token = getToken()
  if (!token) throw new Error("Token error")
  const _query = generateQueryString(query, "CAMEL")
  return requests
    .get(`/v2/my-credit/all${_query}`, token)
    .then((data) => data as CreditAllResultsDto)
    .then((result) => creditAllResultsFromDto(result))
}
export const getMyCreditRecharge = (query?: PageQueryType) => {
  const token = getToken()
  if (!token) throw new Error("Token error")
  const _query = generateQueryString(query, "CAMEL")
  return requests
    .get(`/v2/my-credit/recharge${_query}`, token)
    .then((data) => data as CreditRechargeResultsDto)
    .then((result) => creditRechargeResultsFromDto(result))
}
export const getMyCreditUsage = (query?: PageQueryType) => {
  const token = getToken()
  if (!token) throw new Error("Token error")
  const _query = generateQueryString(query, "CAMEL")
  return requests
    .get(`/v2/my-credit/usage${_query}`, token)
    .then((data) => data as CreditUsageResultsDto)
    .then((result) => creditUsageResultsFromDto(result))
}

export const getVerifyResetPasswordCode = (code: string) => {
  return requests.get(`/v2/accounts/support/reset-password?code=${code}`)
}

export const postResetPasswordEmail = (email: string) => {
  return requests.post("/v2/accounts/support/reset-password", { key: email })
}

export const putResetPassword = (code: string, password: string) => {
  return requests.put(`/v2/accounts/support/reset-password?code=${code}`, { newPassword: password })
}
