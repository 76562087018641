import { putMemberNote } from "@ovision-gis-frontend/shared"
import { Button, CloseOutlined, IconButton, InputField } from "@SIAnalytics/ovision-design-system"
import React, { useState } from "react"

import styles from "./MemoEditor.module.scss"

type Props = {
  onClose: () => void
  // onChange: React.ChangeEventHandler<HTMLTextAreaElement>
  onSubmit: any
  memberId: string
  memberNote: string
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>
}

export default function MemoEditor(props: Props) {
  const [memo, setMemo] = useState(props.memberNote)

  const handleMemoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMemo(e.target.value)
  }

  const handleSubmit = async () => {
    await putMemberNote(props.memberId, memo)

    props.setRefetch((r) => !r)
    props.onClose()
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <span>기타 정보 입력</span>
        <IconButton
          wrapperClassName={styles.iconWrapper}
          size={"small"}
          type={"square"}
          icon={<CloseOutlined />}
          onClick={props.onClose}
        />
      </div>

      <div className={styles.content}>
        <InputField value={memo || ""} onChange={handleMemoChange} onClearButtonClick={() => setMemo("")} />
      </div>

      <div className={styles.footer}>
        <Button type={"outlined"} onClick={props.onClose}>
          취소
        </Button>
        <Button onClick={handleSubmit}>저장</Button>
      </div>
    </div>
  )
}
