import {
  getDateLabel,
  initManagedOrderInfos,
  isError,
  Loading,
  ManagedOrderInfo,
  ManagedOrderInfos,
  PATH_USED_STATUS,
  postAdminOrdersSearchMonitor,
  QueryType,
} from "@ovision-gis-frontend/shared"
import { ArrowRightFilled } from "@SIAnalytics/ovision-design-system"
import { PaginationProps, Table } from "antd"
import type { ColumnsType } from "antd/es/table"
import { ColumnFilterItem } from "antd/es/table/interface"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import TableInfo from "../TableInfo"
import GiveCreditModal from "./GiveCreditModal"
import styles from "./OrderStatus.module.scss"

function OrderStatus() {
  const [refetch, setRefetch] = useState<boolean>(false)
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [orderColumn, setOrderColumn] = useState<"CREATED_TIME">("CREATED_TIME") // TODO: change
  const [orderDirection, setOrderDirection] = useState<"DESC" | "ASC">("DESC")
  const [managedOrderInfos, setManagedOrderInfos] = useState<ManagedOrderInfos>(initManagedOrderInfos)
  const [dataSource, setDataSource] = useState<ManagedOrderInfo[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [emailFilter, setEmailFilter] = useState<ColumnFilterItem[]>([])
  const [planFilter, setPlanFilter] = useState<string[]>([])
  const [snsFilter, setSNSFilter] = useState<string[]>([])
  const [isCreditModalOpen, setIsCreditModalOpen] = useState<boolean>(false)
  const [searchKeyword, setSearchKeyword] = useState<string>("")

  useEffect(() => {
    const postAdminOrdersSearchMonitorAsync = async () => {
      setIsFetching(true)
      const data: QueryType = { pageIndex, orderColumn, orderDirection }
      try {
        const _managedOrderInfos = await postAdminOrdersSearchMonitor(data)
        if (!isError(_managedOrderInfos)) {
          setManagedOrderInfos(_managedOrderInfos)
          setDataSource(_managedOrderInfos.managedOrderInfoList)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setIsFetching(false)
      }
    }

    void postAdminOrdersSearchMonitorAsync()
  }, [orderColumn, orderDirection, pageIndex, refetch])

  const getRequestedCredit = () => {
    if (selectedRowKeys.length === 1) {
      const selectedOrder = dataSource.find((order) => order.orderHistoryId === selectedRowKeys[0])
      if (selectedOrder) return selectedOrder.orderRequestCredit
    }
  }

  const onPageChange: PaginationProps["onChange"] = (page) => {
    const _page = page > 0 ? page - 1 : 0
    setPageIndex(_page)
  }

  const columns: ColumnsType<ManagedOrderInfo> = [
    {
      title: "No.",
      dataIndex: "orderHistoryId",
      key: "orderHistoryId",
      width: "6%",
      render: (text, record, index) => <div>{index + 1 + pageIndex * 50}</div>,
    },
    {
      title: "사용자 계정",
      dataIndex: "memberEmail",
      key: "memberEmail",
      width: "20%",
      render: (text, record) => (
        <Link
          style={{ display: "flex", alignItems: "center", cursor: "pointer", color: "var(--text-main)" }}
          to={PATH_USED_STATUS}
          onClick={(e) => e.stopPropagation()}
        >
          <span style={{ textDecoration: "underline" }}>{record.memberEmail}</span>
          <ArrowRightFilled />
        </Link>
      ),
    },
    {
      title: "요청 일시",
      dataIndex: "orderCreatedTime",
      key: "orderCreatedTime",
      width: "10%",
      render: (text, record) => <div>{getDateLabel(record.orderCreatedTime, "TIL_SEC")}</div>,
    },
    {
      title: "주문 상태",
      dataIndex: "orderStatus",
      key: "orderStatus",
      width: "10%",
      render: (text, record) => {
        if (record.orderStatus === "REQUESTED") return <span>{"결제 요청"}</span>
        else if (record.orderStatus === "PAID") return <span>{"결제됨"}</span>
        else if (record.orderStatus === "DEACTIVATED") return <span>{"계정 비활성화"}</span>
        else if (record.orderStatus === "DONE") return <span style={{ color: "var(--comp-primary)" }}>{"결제 완료"}</span>
        else if (record.orderStatus === "ERROR_OCCURRED")
          return <span style={{ color: "var(--semantic-danger)" }}>{"에러 발생"}</span>
        return <></>
      },
    },
    {
      title: "요청 플랜",
      dataIndex: "planName",
      key: "planName",
      width: "10%",
    },
    {
      title: "요청 크레딧",
      dataIndex: "orderRequestCredit",
      key: "orderRequestCredit",
      width: "10%",
      render: (text, record) => <div>{record.orderRequestCredit.toLocaleString()}</div>,
    },
    {
      title: "보유 크레딧 수량",
      dataIndex: "memberRemainCredit",
      key: "memberRemainCredit",
      width: "10%",
      render: (text, record) => <div>{record.memberRemainCredit.toLocaleString()}</div>,
    },
    {
      title: "기타 정보",
      dataIndex: "memberNote",
      key: "memberNote",
      width: "24%",
    },
  ]

  const getCsvData = () => {
    const csvHeader = columns.map((column) => column.title)
    return [
      csvHeader,
      ...dataSource.map((data) => {
        const _memberNote = data.memberNote.replaceAll("\n", " ")
        return {
          index: data.orderHistoryId,
          email: data.memberEmail,
          orderCreatedTime: data.orderCreatedTime,
          planName: data.planName,
          orderRequestCredit: data.orderRequestCredit,
          remainCredit: data.memberRemainCredit,
          orderStatus: data.orderStatus,
          memo: _memberNote,
        }
      }),
    ]
  }

  const userEmail = [...new Set(managedOrderInfos.managedOrderInfoList.map((order) => order.memberEmail))].map((email) => ({
    text: email,
    value: email,
  }))
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: ManagedOrderInfo[]) => {
      setSelectedRowKeys(selectedRowKeys)
    },
    getCheckboxProps: (record: ManagedOrderInfo) => ({
      disabled: record.orderStatus === "DONE",
      name: record.planName,
    }),
  }

  return (
    <section className={styles.orderStatus}>
      <TableInfo
        className={styles.tableInfo}
        title={"주문 현황"}
        csvData={getCsvData()}
        email={userEmail}
        searchKeyword={searchKeyword}
        selectedRowKeys={selectedRowKeys}
        setEmailFilter={setEmailFilter}
        setPlanFilter={setPlanFilter}
        setSNSFilter={setSNSFilter}
        setSearchKeyword={setSearchKeyword}
        totalInfo={"주문 현황"}
        totalInfoValue={`${managedOrderInfos.totalOrderCount}건`}
        onCreditBtnClick={setIsCreditModalOpen}
      />
      <Table
        className={styles.table}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 50,
          showSizeChanger: false,
          total: managedOrderInfos.totalOrderCount,
          onChange: onPageChange,
        }}
        columns={columns}
        dataSource={dataSource}
        loading={{ indicator: <Loading className={styles.loading} size={"small"} />, spinning: isFetching }}
        rowKey={(record) => record.orderHistoryId}
        rowSelection={{ type: "checkbox", ...rowSelection }}
        scroll={{ y: "calc(100% - 40px)" }} // @NOTE: head height 제외
      />

      {isCreditModalOpen && (
        <GiveCreditModal
          getRequestCredit={getRequestedCredit}
          selectedRowKeys={selectedRowKeys}
          setRefetch={setRefetch}
          onClose={() => setIsCreditModalOpen(false)}
        />
      )}
    </section>
  )
}
export default OrderStatus
