import { DOMAIN_NAME, SERVER_URI } from "./src/common/axiosApi"
import "./i18n"

export const URL_QGIS_DOWNLOAD = "https://www.qgis.org/en/site/forusers/download.html"
//export const URL_DOCS = `https://docs.${DOMAIN_NAME}`
export const URL_DOCS_EN = "https://docs.ovision.ai/ovision/v/eng/guide"
export const URL_DOCS_KO = "https://docs.ovision.ai/ovision/guide"
export const URL_DOCS_GCS_EN = "https://docs.ovision.ai/ovision/v/eng/guide/source/bucket/gcs"
export const URL_DOCS_GCS_KO = "https://docs.ovision.ai/ovision/guide/source/bucket/gcs"
export const URL_CONTACTS = "https://si-analytics.ai/content/contacts.php"

export const REDIRECT_LOGIN = `${SERVER_URI}/login`
export const REDIRECT_LOGIN_PRICING = `${SERVER_URI}/login?redirect_url=https://${DOMAIN_NAME}/pricing`
export const REDIRECT_LOGOUT = `${SERVER_URI}/logout`
export const REDIRECT_LOGOUT_ALL = `${SERVER_URI}/logout-all`
export const REDIRECT_MEMBER_DEACTIVATE = `${SERVER_URI}/member/deactivate`
export const REDIRECT_ADMIN_LOGIN = `${SERVER_URI}/admin/login`
export const REDIRECT_ADMIN_LOGOUT = `${SERVER_URI}/admin/logout`

export const GOOGLE_PATH_LOGIN = "/login"

export const PATH_BACK = -1
export const PATH_HOME = "/"
export const PATH_SIGN_IN = "/sign-in"
export const PATH_OAUTH_GOOGLE = "/sign-in/oauth/google/code"
export const PATH_SIGN_UP = "/sign-up"
export const PATH_ONBOARDING = "/onboarding"
export const PATH_ONBOARDING_EXPIRED = "/onboarding/expired"
export const PATH_VERIFY = "/verify"
export const PATH_VERIFY_RESET_PASSWORD = "/verify/reset-password"
export const SEARCH_REDIRECT = "?redirect="
export const PATH_FORGOT_PASSWORD = "/forgot-password"
export const PATH_ONBOARDING_RESET_PASSWORD = "/onboarding/reset-password"
export const PATH_RESET_PASSWORD = "/reset-password"
export const PATH_RESET_PASSWORD_EXPIRED = "/reset-password/expired"
export const PATH_SIGN_IN_ADD_INFO = "/sign-in/add-info"

/* For Marketing Web */
export const PATH_GEO_AI_PACK = "/"
export const PATH_PRICING = "/pricing"
export const PATH_PRICING_PAYMENT = "/pricing/payment"
export const PATH_PRICING_PAYMENT_AUTHORIZE = "/pricing/payment/authorize"
export const PATH_PRICING_PAYMENT_SUCCESS = "/pricing/payment/success"
export const PATH_PRICING_PAYMENT_FAIL = "/pricing/payment/fail"
export const PATH_MY_ACCOUNT = "/my-account"
export const PATH_CREDIT = "/credit"
export const SEARCH_TAB = "?tab="
export const PATH_PRIVACY_POLICY = "/privacy-policy"
export const PATH_TERMS_AND_CONDITIONS = "/terms-and-conditions"
export const PATH_END_USER_LICENSE_AGREEMENT = "/end-user-license-agreement"

/* For Service Web */
export const PATH_SERVICE_HOME = "/home"
export const PATH_ANALYSIS = "/analysis"
export const PATH_CREATE_PROJECT = "/analysis/create-project"
export const PATH_PROJECT_INFO = "/analysis/project-info"
export const PATH_MY_IMAGES = "/my-images"

/* For Admin workspace */
export const PATH_USER_MANAGE = "/user-manage"
export const PATH_ORDER_STATUS = "/order-status"
export const PATH_USED_STATUS = "/used-status"

/* For Navigate state */
export const OE_EMAIL = "OE_EMAIL"
export const OE_ONBOARDING = "OE_ONBOARDING"
export const OE_PLAN_ID = "OE_PLAN_ID"
export const OE_ORDER = "OE_ORDER"
export const OE_REFERRER = "OE_REFERRER"
export const OE_RESET_PASSWORD_CODE = "OE_RESET_PASSWORD_CODE"
export const OE_REDIRECT_PATH = "OE_REDIRECT_PATH"

export {
  AccessTimeFilled,
  ReportFilled,
  DeleteFilled,
  EmptyFilled,
  StarBorderOutlined,
  CreditCardOutlined,
  OpenInNewOutlined,
  DrawRectangleOutlined,
  MapOutlined,
  AnalyticsOutlined,
  HelpOutlined,
  HomeOutlined,
  FileDownloadOutlined,
  FilterOutlined,
  FitScreenOutlined,
  PhotoFrameOutlined,
  PluginOutlined,
  SettingsOutlined,
  LocationOnOutlined,
  MoreHorizontalOutlined,
  MyLocationOutlined,
  ConnectionsOutlined,
  BackwardOutlined,
  ForwardOutlined,
  ReportOutlined,
  OvisionSymbolLogo,
  OvisionTextSecondaryLogo,
  OvisionTextLogo,
  HOneLogo,
  OvisionLight,
  GoogleLogo,
} from "./src/asset/index"

export { default as SignInLayout } from "./src/components/sign-in/SignInLayout"
export { default as Layout } from "./src/components/layout/Layout"
export { default as ErrorPage } from "./src/components/layout/ErrorPage"
export { default as ProfileMenu } from "./src/components/profile/ProfileMenu"
export { default as Loading } from "./src/components/loading/Loading"
export { default as Fallback } from "./src/components/fallback/Fallback"
export { default as EmptyStatus } from "./src/components/empty-status/EmptyStatus"
export { default as Avatar } from "./src/components/avatar/Avatar"
export { default as Search } from "./src/components/search/Search"
export * from "./src/components/auth"

/* DTO - Start : TODO: hooks와 DTO 분리 */

export type { MemberRequestType, MyAccount } from "./src/hooks/data/myAccountDto"
export { isInitialMyAccount, initMyAccount } from "./src/hooks/data/myAccountDto"
export type { SubscribeInfo } from "./src/hooks/data/SubscribeInfoDto"
export { isInitialSubscribeInfo, initSubscribeInfo } from "./src/hooks/data/SubscribeInfoDto"

export type { AnalysisStatusType, InferenceJobInfo, InferenceJobInfos } from "./src/hooks/data/inferenceJobInfosDto"
export {
  isInitialInferenceJobInfo,
  initInferenceJobInfo,
  initInferenceJobInfos,
  isAnalysisStatusValid,
} from "./src/hooks/data/inferenceJobInfosDto"

export type {
  ManagedInferenceStatusType,
  ManagedInferenceHistoryInfo,
  ManagedInferenceHistoryInfos,
} from "./src/hooks/data/managedInferenceHistoryInfosDto"
export {
  inferenceStatusArray,
  isInferenceStatusValid,
  isInitialManagedInferenceHistoryInfo,
  initManagedInferenceHistoryInfo,
  initManagedInferenceHistoryInfos,
} from "./src/hooks/data/managedInferenceHistoryInfosDto"

export type { MembershipRequestType } from "./src/hooks/data/membershipDto"

export {
  DOMAIN_NAME,
  isError,
  putVerifyEmail,
  postSignOut,
  postSignOutAll,
  postVerifyEmail,
  getMembershipHistoryInfos,
  deleteAccount,
  postSignUp,
  getInferenceJobInfos,
  getInferenceResult,
  deleteInferences,
  postInferences,
  getScenes,
  deleteScenes,
  getMyAccount,
  postAdminOrdersSearchMonitor,
  postAdminInferenceHistoriesSearchMonitor,
  postAdminMembersSearchMonitor,
  postMembership,
  putMemberNote,
  postOrder,
  postScene,
  postSceneUpload,
  patchSceneStatus,
  getScene,
  getAiPacks,
  postAiPacksInquiry,
  getPredictions,
  getSyncStorages,
  postSyncStorages,
  postSyncStoragesInquiry,
  getSyncStoragesProject,
  deleteSyncStorages,
  getProjects,
  postProjects,
  putProjects,
  deleteProject,
  getProjectsInferenceJobs,
  getPlans,
  getSatellites,
  getGsdRange,
  getJobSatellites,
  getJobGsdRange,
  postOrders,
  postPreviewCredit,
  postOrdersCancel,
  postOrdersConfirm,
  getOrdersRefund,
  postOrdersRefund,
  getMyCreditAll,
  getMyCreditRecharge,
  getMyCreditUsage,
  getVerifyResetPasswordCode,
  postResetPasswordEmail,
  putResetPassword,
  getMyPermissions,
} from "./src/common/axiosApi"
export type { QueryType, PageQueryType, ProjectsQueryType, ScenesQueryType, JobsQueryType } from "./src/common/axiosApi"
export { getTimeLabel, getDateLabel, isBaseDateEarlierThanTargetDate } from "./src/common/utils/dateUtils"
export { getCurrencyLabel } from "./src/common/utils/creditUtil"

export type { ManagedMemberInfo, ManagedMemberInfos } from "./src/hooks/data/managedMemberInfosDto"
export {
  isInitialManagedMemberInfo,
  initManagedMemberInfo,
  initManagedMemberInfos,
} from "./src/hooks/data/managedMemberInfosDto"
export type { OrderStatusType, ManagedOrderInfo, ManagedOrderInfos } from "./src/hooks/data/managedOrderInfosDto"
export {
  orderStatusArray,
  isInitialManagedOrderInfo,
  initManagedOrderInfo,
  initManagedOrderInfos,
} from "./src/hooks/data/managedOrderInfosDto"

export type { LayerInfoListType, InferenceRequestType, Inferences } from "./src/hooks/data/inferencesDto"
export { inferencesFromDto, initInferences } from "./src/hooks/data/inferencesDto"

export type { InferenceResultDto, InferenceResult } from "./src/hooks/data/inferenceResultDto"
export { isInitialInferenceResult, inferenceResultFromDto, initInferenceResult } from "./src/hooks/data/inferenceResultDto"

export type { Scene, Scenes, GsdRange } from "./src/hooks/data/scenesDto"
export { isInitialScene, initScene, initScenes } from "./src/hooks/data/scenesDto"

export type { ProviderType, Provider } from "./src/hooks/data/providerDto"
export { isProviderValid, initProvider } from "./src/hooks/data/providerDto"

export type { SearchPredictionType } from "./src/hooks/data/mapboxDto"

export type { AiPackInquiryRequestType, AiPackType, AiPackInfo, AiPackInfos } from "./src/hooks/data/aiPackInfosDto"
export { isInitialAiPackInfo, initAiPackInfo, initAiPackInfos } from "./src/hooks/data/aiPackInfosDto"

export type { ServiceType } from "./src/hooks/data/serviceDto"

export type { MembershipHistoryOrderColumnType, MembershipHistoryInfoType } from "./src/hooks/data/membershipHistoryDto"
export { initMembershipHistoryInfo, initMembershipHistoryInfos } from "./src/hooks/data/membershipHistoryDto"

export type { SourceType, Source } from "./src/hooks/data/sourceDto"
export { isImageSourceValid, initSource } from "./src/hooks/data/sourceDto"

export type {
  DestinationRequestType,
  PutProjectRequestType,
  ProjectStatusType,
  ProjectType,
  ProjectRequestType,
  Project,
  Projects,
} from "./src/hooks/data/projectDto"
export { isInitialProject, initProject, initProjects } from "./src/hooks/data/projectDto"

export type { PlanType, PlanNameType, PlanInfo, PlanInfos } from "./src/hooks/data/planInfosDto"
export { planNameArray, isInitialPlanInfo, initPlanInfo, initPlanInfos } from "./src/hooks/data/planInfosDto"

// Payment
export type { Order, CurrencyType } from "./src/hooks/data/orderDto"
export { isInitialOrder, initOrder } from "./src/hooks/data/orderDto"
export type { Refund } from "./src/hooks/data/refundDto"
export { isInitialRefund, initRefund } from "./src/hooks/data/refundDto"
// My-Credit
export type { CreditAllResultType, CreditAllResult, CreditAllResults } from "./src/hooks/data/creditAllResultsDto"
export { initCreditAllResult, initCreditAllResults } from "./src/hooks/data/creditAllResultsDto"
export type {
  CreditRechargeResultType,
  CreditRechargeResult,
  CreditRechargeResults,
} from "./src/hooks/data/creditRechargeResultsDto"
export {
  initCreditRechargeResult,
  initCreditRechargeResults,
  isInitialCreditRechargeResult,
} from "./src/hooks/data/creditRechargeResultsDto"
export type { CreditUsageResultType, CreditUsageResult, CreditUsageResults } from "./src/hooks/data/creditUsageResultsDto"
export { initCreditUsageResult, initCreditUsageResults } from "./src/hooks/data/creditUsageResultsDto"

/* DTO - End */

export { AuthProvider, useAuth } from "./src/hooks/data/useAuth"
export { useToken, ACCESS_TOKEN_KEY, getToken, REFRESH_TOKEN_KEY } from "./src/hooks/data/useToken"
export { useMember } from "./src/hooks/useMember"

export { ko, en } from "./src/languages"
export type { supportedLanguage } from "./src/languages/LanguageManager"
export { default as LanguageManager, supportedLngs, isSupportedLanguage } from "./src/languages/LanguageManager"

export { useLocalStorage } from "./src/hooks/useLocalStorage"
export { useInterval } from "./src/hooks/useInterval"
export { useElementVisible } from "./src/hooks/useElementVisible"
export { getIsDocumentHidden, usePageVisibility } from "./src/hooks/usePageVisibility"

export type {
  SyncStorageStatus,
  SyncStorageRequestType,
  SyncStorageInquiryRequestType,
  SyncStorage,
  SyncStorages,
  CloudType,
} from "./src/hooks/data/syncStorageDto"
export { isInitialSyncStorage, isCloudTypeValid, initSyncStorage, initSyncStorages } from "./src/hooks/data/syncStorageDto"

export type { LoaderData } from "./src/common/common"
export {
  loadCookie,
  saveCookie,
  deleteCookie,
  keysToCamel,
  changeToCamel,
  decodeThumbnail,
  getFormattedGsd,
  fetchRetry,
  parseJwt,
} from "./src/common/common"

export { koReg, emailReg, passwordReg, contactNumberReg } from "./src/common/regularExpressions"
