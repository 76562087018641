import { Toast } from "@SIAnalytics/ovision-design-system"
import React from "react"
import { Trans, useTranslation } from "react-i18next"

import { postResetPasswordEmail } from "../../.."
import EmailOnboardingLayout, { useEmail } from "./EmailOnboardingLayout"
import styles from "./EmailOnboardingLayout.module.scss"

function ResetPasswordOnboarding() {
  const { t } = useTranslation()

  const email = useEmail()

  const sendEmail = async () => {
    try {
      await postResetPasswordEmail(email)
      Toast({ message: t("toast.verifyEmail.resend.success"), type: "success" })
    } catch (e) {
      Toast({ message: t("toast.verifyEmail.resend.error"), type: "error" })
    }
  }

  return (
    <EmailOnboardingLayout
      title={t("verifyEmail.title")}
      desc={
        <Trans i18nKey={"passwordResetEmail.desc"}>
          We have sent a password reset link to <strong>email@email.com</strong> Don’t receive a password reset email?
          <span className={styles.resendBtn} onClick={sendEmail}>
            Resend
          </span>
          {{ email: email }}
        </Trans>
      }
      email={email}
    />
  )
}

export default ResetPasswordOnboarding
