import { useToken, LoaderData, EmptyStatus, useMember, PATH_SIGN_IN, isInitialMyAccount } from "@ovision-gis-frontend/shared"
import { Toast } from "@SIAnalytics/ovision-design-system"
import { ConfigProvider as AntdConfigProvider } from "antd"
import React from "react"
import { Navigate, Outlet, useLoaderData } from "react-router-dom"

import styles from "./Home.module.scss"
import SideNavigationBar from "./SideNavigationBar"

export const HomeLoader = async () => {
  return useMember()
}
const renderEmpty = () => {
  return <EmptyStatus title={"데이터 없음"} />
}

function Home() {
  const { user, permissions } = useLoaderData() as LoaderData<typeof HomeLoader>
  const { token } = useToken()

  if (!token || isInitialMyAccount(user)) {
    Toast({ type: "error", message: "유저 정보가 없습니다." })
    return <Navigate replace={true} to={PATH_SIGN_IN} />
  } else if (!permissions.find((value) => value === "permission.role.admin")) {
    Toast({ type: "error", message: "관리자 권한이 없습니다." })
    return <Navigate replace={true} to={PATH_SIGN_IN} />
  }

  return (
    <div className={styles.home}>
      <SideNavigationBar className={styles.sideNavigationBar} user={user} />
      <AntdConfigProvider renderEmpty={renderEmpty}>
        <Outlet />
      </AntdConfigProvider>
    </div>
  )
}
export default Home
