import { ArrowBackOutlined, Button, IconButton, InputField, Toast } from "@SIAnalytics/ovision-design-system"
import { AxiosResponse } from "axios"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"

import {
  OE_EMAIL,
  OE_ONBOARDING,
  PATH_ONBOARDING,
  PATH_ONBOARDING_RESET_PASSWORD,
  PATH_SIGN_IN,
  postResetPasswordEmail,
} from "../../.."
import { emailReg } from "../../common/regularExpressions"
import styles from "./ForgotPassword.module.scss"

function ForgotPassword() {
  const [email, setEmail] = useState<string>("")
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true)
  const [isEmailNotRegistered, setIsEmailNotRegistered] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isOnboardingSuccessAlerted, setIsOnboardingSuccessAlerted] = useState<boolean>(false)
  const location = useLocation()
  const { t } = useTranslation()
  const navigate = useNavigate()

  if (location.state) {
    const onboarding = OE_ONBOARDING in location.state ? location.state[OE_ONBOARDING] : false
    if (onboarding && !isOnboardingSuccessAlerted) {
      Toast({ message: t("toast.signIn.verification.success"), type: "success" })
      setIsOnboardingSuccessAlerted(true)
    }
  }

  const handleEmailInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
    resetEmailInputState()
  }

  const resetEmailInputState = () => {
    setIsEmailValid(true)
    setIsEmailNotRegistered(false)
  }

  const checkValidEmail = () => {
    const isValid = emailReg.test(email)
    setIsEmailValid(isValid)
    return isValid
  }

  const sendEmail = async () => {
    setIsLoading(true)
    try {
      await postResetPasswordEmail(email)
      navigate(PATH_ONBOARDING_RESET_PASSWORD, { replace: true, state: { [OE_EMAIL]: email } })
    } catch (e) {
      const res = e as AxiosResponse
      if (res.status === 403) {
        Toast({ message: t("toast.resetPassword.notVerifiedEmail.error"), type: "error" })
        navigate(PATH_ONBOARDING, { replace: true, state: { [OE_EMAIL]: email } })
      } else {
        setIsEmailNotRegistered(true)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmit = async () => {
    if (!checkValidEmail()) return
    await sendEmail()
  }

  const getEmailInputHelperText = () => {
    if (!isEmailValid) return t("textInput.email.helperText.invalid")
    else if (isEmailNotRegistered) return t("textInput.email.helperText.notRegistered")
    else return ""
  }

  return (
    <div className={styles.forgotPassword}>
      <div className={styles.content}>
        <div className={styles.titleContainer}>
          <IconButton
            tabIndex={-1}
            size={"large"}
            type={"square"}
            htmlType={"button"}
            icon={<ArrowBackOutlined />}
            onClick={() => navigate(PATH_SIGN_IN, { replace: true })}
            onSubmit={(e) => e.preventDefault()}
          />
          <p>{t("forgotPassword.title")}</p>
        </div>
        <p className={styles.desc}>{t("forgotPassword.desc")}</p>
        <form onSubmit={(e) => e.preventDefault()}>
          <InputField
            id={"email"}
            tabIndex={1}
            size={"large"}
            type={"text"}
            autoComplete={"email"}
            autoFocus={true}
            helperText={getEmailInputHelperText()}
            label={t("textInput.email.label") ?? ""}
            placeholder={t("textInput.placeholder", { target: t("textInput.email.label").toLowerCase() }) ?? ""}
            state={!isEmailValid || isEmailNotRegistered ? "error" : "default"}
            value={email}
            onChange={handleEmailInput}
            onClearButtonClick={() => setEmail("")}
          />
          <Button
            size={"xl"}
            type={"cta"}
            disabled={email.length === 0}
            htmlType={"submit"}
            loading={isLoading}
            onClick={handleSubmit}
          >
            {t("button.send")}
          </Button>
        </form>
      </div>
    </div>
  )
}

export default ForgotPassword
